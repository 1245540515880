
<template>
    <div>
        <div class="col-12 border-bottom mb-4 pl-0 top-header-sticky">
            <div class="d-flex justify-content-between align-items-end pb-1">
                <h1 class="mb-0">FYC Event Order</h1>
                <div class="entry-info-number">
                    <div class="entry-id-wrapper d-flex align-items-end">
                        <div class="mr-4">
                            <label class="membership-type">Order Status</label>
                            <div class="membership-label" :class="orderinfo.order_status == 'submitted' ? 'submitted-color' : ''">
                                {{ submission_statuses[orderinfo.order_status] }}
                            </div>
                        </div>
                        <div class="mr-4">
                            <label class="membership-type">Asset Status</label>
                            <div class="membership-label" :class="orderinfo.asset_status == 'submitted' ? 'submitted-color' : ''">
                                {{ asset_statuses[orderinfo.asset_status] }}
                            </div>
                        </div>
                        <div>
                            <span class="label small-caps">Event No.</span>
                            <span class="value" id="entryId">{{ orderinfo.order_id }}</span>
                        </div>
                        <div class="form-actions form-actions-edit" v-if="role != 'viewer'">
                            <a href="#" @click.prevent="updateForm('','',true)" class="btn btn-primary btn-icon" id="SaveButton" v-if="role == 'admin'">
                                <save-icon></save-icon>
                            </a>
                            <a :href="'/event/'+orderinfo.order_id+'/download_assets'" class="btn btn-primary btn-icon mr-1" id="AssetButton" v-if="role == 'admin'">
                                <download class="svg-black"></download>Assets
                            </a>
                            <a :href="'/event/'+orderinfo.order_id+'/download_video_assets'" class="btn btn-primary btn-icon" id="VideoAssetButton" v-if="role == 'admin'">
                                <download class="svg-black"></download>Video
                            </a>
                            <order-delete :deleteurl="actionurl" v-if="role == 'admin'"></order-delete>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="full-form-wrapper-except-sticky">
        <div class="mb-4 mt-0 event-order-steps-menu" v-if="role == 'admin'">
            <admin-statuses
                type="event"
                :actionurl_assets="actionurl_assets"
                :actionurl_details="actionurl_details"
                :actionurl_send_at_time="actionurl_send_at_time"
                :actionurl_team_assigned="actionurl_team_assigned"
                :asset_statuses="asset_statuses"
                :invoices="invoices"
                :migrateurl="migrateurl"
                :order="orderinfo"
                :submission_statuses="submission_statuses"
                :team_assigned_members="team_assigned_members"
                :updateurl="actionurl"
                :user_change_url="user_change_url"
                :user_suggestions_url="user_suggestions_url"
            ></admin-statuses>
        </div>

        <ul class="accordion-tabs mb-5" v-if="role == 'admin'">
            <li class="nav-item d-inline-block">
                <a class="tab-link is-active" :href="actionurl">Order Details</a>
            </li>
            <li class="nav-item d-inline-block">
                <a class="tab-link" :href="actionurl + '/logs'">Change Log</a>
            </li>
            <li class="nav-item d-inline-block">
                <a class="tab-link" :href="actionurl + '/consolidation'">Content Consolidation</a>
            </li>
        </ul>

        <div class="accordion-item accordion-contact-details-wrapper mb-3" id="order-step-0">
            <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between" id="order-step--1">
                <a @click.prevent="applicationStepSelector(0)" class="w-100 header-button position-relative d-flex justify-content-between accordion-button pl-30 pr-30 py-3 py-lg-2" :class="dynamicClass(0)">
                    <div class="position-absolute app-status-icons">
                        <Attention svg-class="svg-24 red-svg" v-if="iconArray.details && iconArray.details == 'incomplete'"></Attention>
                        <checkmark svg-class="svg-24 svg-green" v-if="iconArray.details && iconArray.details == 'complete'"></checkmark>
                    </div>
                    <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header">
                        <h3 class="mb-0">Event Details</h3>
                        <div class="d-flex align-items-center w-25" :class="statusForIndicators.includes(orderinfo.order_status) ? 'justify-content-between' : 'justify-content-end'">
                            <div class="d-flex align-items-center cursor-initial" :class="'pr-2 status-color-' + orderinfo.order_status" v-if="statusForIndicators.includes(orderinfo.order_status)">
                                <submitted svg-class="svg-24 mr-2" v-if="orderinfo.order_status == 'submitted'"></submitted>
                                <question svg-class="svg-24 mr-2" v-if="orderinfo.order_status == 'need_more_info'"></question>
                                <circle_check svg-class="svg-24 mr-2" v-if="orderinfo.order_status == 'approved' || orderinfo.order_status == 'order_completed'"></circle_check>
                                <span class="text-uppercase">{{ orderinfo.order_status.replace(/_/g, ' ') }}</span>
                            </div>
                            <up-chevron-icon class="svg-accent" :class="(orderinfo.application_step == 0) ? 'rotate-0' : 'rotate-180'"></up-chevron-icon>
                        </div>
                    </div>
                </a>
            </div>
            <div id="accordion-order-details" class="pt-3 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="(orderinfo.application_step == null || orderinfo.application_step == 0) ? 'show' : ''">
                <div class="text-right red" v-if="orderinfo.needs_more_info_text && update_allowed['details'] && role != 'admin'">{{ orderinfo.needs_more_info_text }}</div>

                <div class="mt-3 fields-wrapper-row pl-30">
                    <h3 class="order-header">Event Type</h3>
                    <div class="w-66 d-flex align-items-center justify-content-between mt-2 ml-4" :class="errorObject['details']['type'] ? 'required' : ''">
                        <div class="custom-control custom-radio d-flex align-items-center pl-0">
                            <input v-if="update_allowed['details']" @change="updateForm('details','type',false)" type="radio" v-model="orderinfo.type" value="In-Person" class="custom-control-input" id="orderinfo.type_in_person" name="orderinfo.type">
                            <div v-else>
                                <circle-filled-icon v-if="orderinfo.type == 'In-Person'"></circle-filled-icon>
                                <circle_icon v-else></circle_icon>
                            </div>
                            <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.type_in_person">In Person</label>
                        </div>
                        <div class="custom-control custom-radio d-flex align-items-center pl-0">
                            <input v-if="update_allowed['details']" @change="updateForm('details','type',false)" type="radio" v-model="orderinfo.type" value="Virtual" class="custom-control-input" id="orderinfo.type_virtual" name="orderinfo.type">
                            <div v-else>
                                <circle-filled-icon v-if="orderinfo.type == 'Virtual'"></circle-filled-icon>
                                <circle_icon v-else></circle_icon>
                            </div>
                            <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.type_virtual">Virtual</label>
                        </div>
                        <div class="custom-control custom-radio d-flex align-items-center pl-0">
                            <input v-if="update_allowed['details']" @change="updateForm('details','type',false)" type="radio" v-model="orderinfo.type" value="Hybrid" class="custom-control-input" id="orderinfo.type_hybrid" name="orderinfo.type">
                            <div v-else>
                                <circle-filled-icon v-if="orderinfo.type == 'Hybrid'"></circle-filled-icon>
                                <circle_icon v-else></circle_icon>
                            </div>
                            <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.type_hybrid">Hybrid</label>
                        </div>
                    </div>

                    <div class="mt-5 fields-wrapper-row">
                        <h3 class="order-header">Submitter Contact</h3>
                        <div class="form-group-beside row" :class="errorObject['details']['your_name'] ? 'required' : ''">
                            <label for="your_name" class="col-2 col-md-6">Your Name: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details', 'your_name', false)" type="text" v-model="orderinfo.your_name" name="your_name" id="your_name" class="form-control your_name order_details_field" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['details']['your_email'] ? 'required' : ''">
                            <label for="your_email" class="col-2 col-md-6">
                                Your Email: <em class="yellow no-style">*</em>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details', 'your_email', false)" type="text" v-model="orderinfo.your_email" name="your_email" id="your_email" class="form-control your_email order_details_field" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['details']['your_phone'] ? 'required' : ''">
                            <label for="your_phone" class="col-2 col-md-6">Your Phone: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details', 'your_phone', false)" type="text" v-model="orderinfo.your_phone" id="your_phone" class="form-control your_phone order_details_field" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>

                        <div class="form-group-beside row" :class="errorObject['details']['contact_email_cc'] ? 'required' : ''">
                            <label for="contact_email_cc" class="col-2 col-md-6">
                                Email CC List:
                                <div class="description">
                                    Please list all email address to copy on order emails, comma separated. ex: email@email.com, email@email.com, email@email.com
                                </div>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details', 'contact_email_cc', false)" type="text" v-model="orderinfo.contact_email_cc" id="contact_email_cc" class="form-control contact_email_cc order_details_field" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>

                        <div class="form-group-beside row">
                            <label for="contact_notes" class="col-2 col-md-6">
                                Event Notes:
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <textarea @change="updateForm('details', 'contact_notes', false)" rows="5" type="text" id="contact_notes" class="form-control" v-model="orderinfo.contact_notes" :disabled="(role == 'viewer')?true:false"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="full-instructions-wrapper fields-wrapper-row">
                        <h3 class="order-header">Billing Contact</h3>
                        <div class="form-group-beside row" :class="errorObject['details']['billing_company'] ? 'required' : ''">
                            <label for="billing_company" class="col-2 col-md-6">Company to be Billed:</label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details','billing_company', false)" type="text" v-model="orderinfo.billing_company" name="billing_company" id="billing_company" class="form-control billing_company" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['details']['billing_name'] ? 'required' : ''">
                            <label for="billing_name" class="col-2 col-md-6">Billing Contact: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details','billing_name', false)" type="text" v-model="orderinfo.billing_name" name="billing_name" id="billing_name" class="form-control billing_name" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['details']['billing_email'] ? 'required' : ''">
                            <label for="billing_email" class="col-2 col-md-6">Billing Email: <em class="yellow no-style">*</em>
                                <div class="description pl-4 mt-1" v-if="errorObject['details'] && errorObject['details']['billing_email']"><p class="red-font">{{ errorObject['details']['billing_email'][0] }}</p></div>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details','billing_email',false)" type="text" v-model="orderinfo.billing_email" id="billing_email" class="form-control billing_email" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['details']['billing_phone'] ? 'required' : ''">
                            <label for="billing_phone" class="col-2 col-md-6">Billing Phone: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details','billing_phone',false)" type="text" v-model="orderinfo.billing_phone" id="billing_phone" class="form-control billing_phone" autocomplete="on" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>

                        <h4 class="mb-0 mt-4 f-400">Billing Address</h4>
                        <div class="form-group-beside row" :class="errorObject['details']['billing_address.street_1'] && errorObject['details']['billing_address.street_1'] ? 'required' : ''">
                            <label for="billing_address" class="col-2 col-md-6">Street 1: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.billing_address != null">
                                <input @change="updateForm('details','billing_address.street_1',false)" type="text" id="billing_address" class="form-control" v-model="orderinfo.billing_address.street_1" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['details']['billing_address.street_2'] && errorObject['details']['billing_address.street_2'] ? 'required' : ''">
                            <label for="billing_street_2" class="col-2 col-md-6">Street 2:</label>
                            <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.billing_address != null">
                                <input @change="updateForm('details','billing_address.street_2',false)" type="text" id="billing_street_2" class="form-control" v-model="orderinfo.billing_address.street_2" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['details']['billing_address.city'] && errorObject['details']['billing_address.city'] ? 'required' : ''">
                            <label for="billing_address_city" class="col-2 col-md-6">City: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.billing_address != null">
                                <input @change="updateForm('details','billing_address.city',false)" type="text" id="billing_address_city" class="form-control" v-model="orderinfo.billing_address.city" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['details']['billing_address.state'] && errorObject['details']['billing_address.state'] ? 'required' : ''">
                            <label for="billing_address_state" class="col-2 col-md-6">State: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.billing_address != null">
                                <v-select v-if="update_allowed['details']" id="billing_address['state']" placeholder="Select option" v-model="orderinfo.billing_address.state" :options="states" @input="updateForm('details','billing_address.state',false)"></v-select>
                                <span class="readonly pl-10" v-text="orderinfo.billing_address['state']" v-if="!update_allowed['details']"></span>
                            </div>
                        </div>
                        <div class="form-group-beside row" :class="errorObject['details']['billing_address.postal_code'] && errorObject['details']['billing_address.postal_code'] ? 'required' : ''">
                            <label for="billing_address_postal_code" class="col-2 col-md-6">ZIP Code: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.billing_address != null">
                                <input @change="updateForm('details','billing_address.postal_code',false)" maxlength="10" type="text" id="billing_address_postal_code" class="form-control" v-model="orderinfo.billing_address.postal_code" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                    </div>


                    <h3 class="order-header mt-5">Program Details</h3>
                    <div class="form-group-beside row" :class="errorObject['details']['program_title'] ? 'required' : ''">
                        <label for="program_title" class="col-2 col-md-6">Program Title: <em class="yellow no-style">*</em></label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <input @change="updateForm('details','program_title',false)" type="text" v-model="orderinfo.program_title" id="program_title"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control program_title" :disabled="(role == 'viewer')?true:false">
                        </div>
                    </div>

                    <div class="form-group-beside row program_logo_preview_wrapper image_with_preview" :class="errorObject['details']['program_logo'] ? 'required' : ''">
                        <label for="program_logo" class="col-2 col-md-6">
                            <div>Program Logo: <em class="yellow no-style">*</em></div>
                            <div class="description">Must be square, 600x600px minimum, .jpg or .png<br>Image (logo or key art) must include program title and cannot have a transparent background.</div>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper image-input-wrapper square">
                            <fyc-cropper
                                v-if="update_allowed['details']"
                                indexval="1"
                                :initval="orderinfo.program_logo"
                                min-crop-width="600"
                                min-crop-height="600"
                                @deleteImage="deleteImage('program_logo')"
                                @croppedImage="saveForValidation"
                                :file-size-restriction="true"
                                machine_name="program_logo"
                                :updating-form="updatingForm"
                                :role="role"
                            >
                            </fyc-cropper>
                          <div class="small-img" v-if="!update_allowed['details']">
                                <img :src="orderinfo.program_logo">
                            </div>
                        </div>
                    </div>

                    <div class="form-group-beside row select-box-bigger" :class="errorObject['details']['network_platform'] ? 'required' : ''">
                        <label :for="'network_platform'" class="col-2 col-md-6">
                            Network or Platform: <em class="yellow no-style">*</em>
                            <div class="description">If not listed or submitting for studio, select other.</div>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <v-select v-if="update_allowed['details']" id="network_platform" placeholder="Select option" v-model="orderinfo.network_platform" :options="cable_networkOptions" @input="updateForm('details','network_platform',false)"></v-select>
                            <span class="readonly pl-10" v-text="orderinfo.network_platform" v-if="!update_allowed['details']"></span>
                        </div>
                    </div>

                    <div class="form-group-beside row" v-if="orderinfo.network_platform == 'Other'" :class="errorObject['details']['network_platform_other'] ? 'required' : ''">
                        <label for="network_platform_other" class="col-2 col-md-6">If other, list here: </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <input @change="updateForm('details','network_platform_other',false)" type="text" v-model="orderinfo.network_platform_other" id="network_platform_other" class="form-control network_platform_other" :disabled="(role == 'viewer')?true:false">
                        </div>
                    </div>

                    <div class="form-group-beside row image_with_preview" :class="errorObject['details']['cable_network_logo'] ? 'required' : ''">
                        <label for="cable_network_logo" class="col-2 col-md-6">
                            <div>Broadcast/Cable Network, Studio or Platform Logo: <em class="yellow no-style">*</em></div>
                            <div class="description">Must be square image, 600x600px minimum, .jpg or .png, not on a transparent background.</div>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper image-input-wrapper square">
                            <fyc-cropper
                                v-if="update_allowed['details']"
                                indexval="2"
                                :initval="orderinfo.cable_network_logo"
                                min-crop-width="600"
                                min-crop-height="600"
                                @croppedImage="saveForValidation"
                                :file-size-restriction="true"
                                @deleteImage="deleteImage('cable_network_logo')"
                                machine_name="cable_network_logo"
                                :updating-form="updatingForm"
                                :role="role"
                            >
                            </fyc-cropper>
                            <div class="small-img" v-else>
                                <img :src="orderinfo.cable_network_logo">
                            </div>
                        </div>
                    </div>

                    <h3 class="order-header mt-5">Event Information</h3>

                    <div class="form-group-beside row" :class="errorObject['details']['event_title'] ? 'required' : ''">
                        <label for="event_title" class="col-2 col-md-6">
                            Event Title: <em class="yellow no-style">*</em>
                            <div class="description">This should be your program title, unless your event features multiple titles. If your event location is in New York, please add (NY) after your event title. Ex: Event Title (NY)</div>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <input @change="updateForm('details','event_title',false)" type="text" v-model="orderinfo.event_title" id="event_title"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control event_title" :disabled="(role == 'viewer')?true:false">
                        </div>
                    </div>

                    <div class="form-calendar form-group-beside row" :class="errorObject['details']['event_date'] ? 'required' : ''">
                        <label for="event_date" class="col-2 col-md-6">
                            <div>Event Date: <em class="yellow no-style">*</em></div>
                            <div class="description">
                                Event date is the date you chose from the FYC Event lottery in January.
                            </div>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper input-wrapper-date" :class="!update_allowed['details'] ? 'd-flex align-items-center' : ''">
                            <calendar-icon data-toggle class="svg-24 svg-accent d-inline-block"></calendar-icon>
                            <flat-pickr @on-change="eventDateConfigCallback" id="event_date" v-model="orderinfo.event_date" :config="dateConfig"
                                        placeholder="Select date" v-if="update_allowed['details'] && role == 'admin'"></flat-pickr>
                            <span class="readonly-date" v-text="orderinfo.event_date" v-else></span>
                            <div class="input-group-append">
                                <a class="date-clear-button" type="button" title="Clear" data-clear v-if="update_allowed['details']">
                                    <close-icon></close-icon>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="form-group-beside row select-box-bigger" :class="errorObject['details']['time_slot'] ? 'required' : ''">
                        <label :for="'eventTime'" class="col-2 col-md-6">Event Time: <em class="yellow no-style">*</em>
                            <div class="description">
                                If your event originates from the East Coast, we recommend booking:
                                <ul class="ml-3">
                                    <li>~ Weekends: 11:00 AM PT or 3:00 PM PT</li>
                                    <li>~ Weeknights: 4:00 PM PT in-person or 5:00 PM PT virtual</li>
                                </ul>
                            </div>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <v-select label="label" :reduce="label => label.code" v-if="update_allowed['details'] && role == 'admin'" id="eventTime" placeholder="Select option" v-model="orderinfo.time_slot" :options="eventTimeOptions" @input="updateForm('details','time_slot',false)"></v-select>
                            <span class="readonly pl-10" v-text="orderinfo.time_slot" v-if="!update_allowed['details']"></span>
                        </div>
                    </div>

                    <div class="form-group-beside row select-box-bigger" :class="errorObject['details']['event_location'] ? 'required' : ''">
                        <label :for="'event_location'" class="col-2 col-md-6">Event Location: <em class="yellow no-style">*</em>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <v-select label="label" :reduce="label => label.code" v-if="update_allowed['details']" id="event_location" placeholder="Select option" v-model="orderinfo.event_location" :options="eventLocationOptions" @input="updateForm('details','time_slot',false)"></v-select>
                            <span class="readonly pl-10" v-text="orderinfo.event_location" v-if="!update_allowed['details']"></span>
                        </div>
                    </div>

                    <div class="form-group-beside row" :class="errorObject['details']['event_location_other'] ? 'required' : ''" v-if="orderinfo.event_location == 'Other'">
                        <label for="event_location_other" class="col-2 col-md-6">Event Location (Other) </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <input @change="updateForm('details','event_location_other',false)" type="text" v-model="orderinfo.event_location_other" id="event_location_other" class="form-control event_location_other" :disabled="(role == 'viewer')?true:false">
                        </div>
                    </div>


                    <div class="form-group-beside row select-box-bigger" v-if="orderinfo.time_slot == 'Open'" :class="errorObject['details']['time_slot_other'] ? 'required' : ''">
                        <label :for="'eventTime'" class="col-2 col-md-6">Desired Time Slot: <em class="yellow no-style">*</em>
                            <div class="description">(All times in PT)</div>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <input @change="updateForm('details','time_slot_other',false)" type="text" v-model="orderinfo.time_slot_other" id="eventTimeOther" class="form-control eventTimeOther" :disabled="(role == 'viewer')?true:false">
                        </div>
                    </div>

                    <div class="form-group-beside row select-box-bigger" v-if="role == 'admin' && orderinfo.time_slot != 'Open'">
                        <label :for="'eventTime'" class="col-2 col-md-6">Admin Approved Time Change: <em class="yellow no-style">*</em>
                            <div class="description">(All times are in the event’s local time zone)</div>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <input @change="updateForm('details','time_slot',false)" type="text" v-model="orderinfo.time_slot" id="eventTimeAdmin" class="form-control eventTime" :disabled="(role == 'viewer')?true:false">
                        </div>
                    </div>

                    <div class="form-group-beside row" :class="errorObject['details'] && errorObject['details']['cohosted'] ? 'required' : ''">
                        <label for="cohosted" class="col-2 col-md-6">
                            Is this a co-hosted event? <em class="yellow no-style">*</em>
                            <div class="description">
                                A joint event with a previously scheduled premiere, festival panel and screening, etc.
                            </div>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <div class="toggle-js">
                                <span class="yes-toggle" :class="toggleClass['cohosted']" @click="update_allowed['details'] ? toggleFunctionality('cohosted',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                <span class="no-toggle"  :class="toggleClass['cohosted']" @click="update_allowed['details'] ? toggleFunctionality('cohosted',0, true, 'details') : ''"><close-icon></close-icon> No</span>
                            </div>
                            <input @change="updateForm('details','cohosted',false)" type="hidden" v-model="orderinfo.cohosted" id="cohosted" class="form-control cohosted">
                        </div>
                    </div>

                    <div class="form-group-beside row" :class="errorObject['details']['cohosted_with'] ? 'required' : ''" v-if="orderinfo.cohosted == 1">
                        <label for="cohosted_with" class="col-2 col-md-6">Who is the event co-hosted with? <em class="yellow no-style">*</em></label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <input @change="updateForm('details','cohosted_with',false)" type="text" v-model="orderinfo.cohosted_with" id="cohosted_with" class="form-control cohosted_with" :disabled="(role == 'viewer')?true:false">
                        </div>
                    </div>

                    <div class="form-group-beside row" :class="errorObject['details']['seats_for_tva'] ? 'required' : ''" v-if="orderinfo.type != 'Virtual' && orderinfo.cohosted == 1">
                        <label for="seats_for_tva" class="col-2 col-md-6">How many seats will be reserved for Television Academy members? <em class="yellow no-style">*</em></label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <input @change="updateForm('details','seats_for_tva',false)" type="text" v-model="orderinfo.seats_for_tva" id="seats_for_tva" class="form-control seats_for_tva" :disabled="(role == 'viewer')?true:false">
                        </div>
                    </div>

                    <div class="form-group-beside row" :class="errorObject['details'] && errorObject['details']['reserved_section'] ? 'required' : ''" v-if="orderinfo.type != 'Virtual' && orderinfo.cohosted == 1">
                        <label for="reserved_section" class="col-2 col-md-6">
                            Will there be a reserved section or seating mixed into the audience? <em class="yellow no-style">*</em>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <input @change="updateForm('details','reserved_section',false)" type="text" v-model="orderinfo.reserved_section" id="reserved_section" class="form-control reserved_section" :disabled="(role == 'viewer')?true:false">
                        </div>
                    </div>

                    <div class="form-group-beside row" :class="errorObject['details']['cohosted_special_line'] ? 'required' : ''" v-if="orderinfo.type != 'Virtual' && orderinfo.cohosted == 1">
                        <label for="cohosted_special_line" class="col-2 col-md-6">Is there a special line for Television Academy members? <em class="yellow no-style">*</em></label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <div class="toggle-js">
                                <span class="yes-toggle" :class="toggleClass['cohosted_special_line']" @click="update_allowed['details'] ? toggleFunctionality('cohosted_special_line','1', true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                <span class="no-toggle" :class="toggleClass['cohosted_special_line']" @click="update_allowed['details'] ? toggleFunctionality('cohosted_special_line','0', true, 'details') : ''"><close-icon></close-icon> No</span>
                            </div>
                            <input @change="updateForm('details','cohosted_special_line',false)" type="hidden" v-model="orderinfo.cohosted_special_line" id="cohosted_special_line" class="form-control cohosted_special_line">
                        </div>
                    </div>

                    <div class="form-group-beside row" :class="errorObject['details']['cohosted_hosted_bar'] ? 'required' : ''" v-if="orderinfo.type != 'Virtual' && orderinfo.cohosted == 1">
                        <label for="cohosted_hosted_bar" class="col-2 col-md-6">Is there a hosted bar (food & beverage)? <em class="yellow no-style">*</em></label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <div class="toggle-js">
                                <span class="yes-toggle" :class="toggleClass['cohosted_hosted_bar']" @click="update_allowed['details'] ? toggleFunctionality('cohosted_hosted_bar','1', true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                <span class="no-toggle" :class="toggleClass['cohosted_hosted_bar']" @click="update_allowed['details'] ? toggleFunctionality('cohosted_hosted_bar','0', true, 'details') : ''"><close-icon></close-icon> No</span>
                            </div>
                            <input @change="updateForm('details','cohosted_hosted_bar',false)" type="hidden" v-model="orderinfo.cohosted_hosted_bar" id="cohosted_hosted_bar" class="form-control cohosted_hosted_bar">
                        </div>
                    </div>

                    <div class="form-group-beside row" :class="errorObject['details']['cohosted_concession'] ? 'required' : ''" v-if="orderinfo.type != 'Virtual' && orderinfo.cohosted == 1">
                        <label for="cohosted_concession" class="col-2 col-md-6">If no, will there be concession stands to purchase food and beverage? <em class="yellow no-style">*</em></label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <div class="toggle-js">
                                <span class="yes-toggle" :class="toggleClass['cohosted_concession']" @click="update_allowed['details'] ? toggleFunctionality('cohosted_concession','1', true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                <span class="no-toggle" :class="toggleClass['cohosted_concession']" @click="update_allowed['details'] ? toggleFunctionality('cohosted_concession','0', true, 'details') : ''"><close-icon></close-icon> No</span>
                            </div>
                            <input @change="updateForm('details','cohosted_concession',false)" type="hidden" v-model="orderinfo.cohosted_concession" id="cohosted_concession" class="form-control cohosted_concession">
                        </div>
                    </div>

                    <div class="wysiwyg-content form-group-beside row invite-wysiwyg-field" :class="errorObject['details']['invite_description'] ? 'required' : ''">
                        <label :for="'invite_description'" class="col-12 col-md-6">
                            Invite Description, Event Schedule, Panelists, Moderator, Titles: <em class="yellow no-style">*</em>
                            <div class="description" v-html="invite_description_example">

                            </div>
                        </label>
                        <div class="col-12 col-md-6 mb-2">
                            <wysiwyg-input
                                v-if="update_allowed['details']"
                                :include-html-editing-option="(role === 'admin')"
                                :model-value="orderinfo.invite_description"
                                @update-model-value="(value) => { orderinfo.invite_description = value; updateForm('details','invite_description',false); }"
                            ></wysiwyg-input>
                            <div
                                v-else
                                class="wysiwyg-placeholder wysiwyg-content p-2"
                                v-html="orderinfo.invite_description"
                            ></div>
                        </div>
                    </div>

                    <section>
                        <h3 class="order-header mt-5" v-if="orderinfo.type != 'Virtual'">Guest Management Information</h3>
<!--                        removed include_associate_members per JENNY Feb 22, 2024 -->
<!--                        They said save it in case they bring it back -->
<!--                        <div class="form-group-beside row" :class="errorObject['details']['include_associate_members'] ? 'required' : ''">-->
<!--                            <label for="include_associate_members" class="col-2 col-md-6">-->
<!--                                Include Associate (non-voting) Members? <em class="yellow no-style">*</em>-->
<!--                            </label>-->
<!--                            <div class="col-10 col-md-6 input-wrapper">-->
<!--                                <div class="toggle-js">-->
<!--                                    <span class="yes-toggle" :class="toggleClass['include_associate_members']" @click="update_allowed['details'] ? toggleFunctionality('include_associate_members',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>-->
<!--                                    <span class="no-toggle" :class="toggleClass['include_associate_members']" @click="update_allowed['details'] ? toggleFunctionality('include_associate_members',0, true, 'details') : ''"><close-icon></close-icon> No</span>-->
<!--                                </div>-->
<!--                                <input @change="updateForm('details','include_associate_members',false)" type="hidden" v-model="orderinfo.include_associate_members" id="include_associate_members" class="form-control include_associate_members">-->
<!--                            </div>-->
<!--                        </div>-->

                        <div class="form-group-beside row" :class="errorObject['details']['allow_plus_one'] ? 'required' : ''" v-if="orderinfo.type != 'Virtual'">
                            <label for="allow_plus_one" class="col-2 col-md-6">
                                Do you allow a plus-one? <em class="yellow no-style">*</em>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <div class="toggle-js">
                                    <span class="yes-toggle" :class="toggleClass['allow_plus_one']" @click="update_allowed['details'] ? toggleFunctionality('allow_plus_one',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                    <span class="no-toggle"  :class="toggleClass['allow_plus_one']" @click="update_allowed['details'] ? toggleFunctionality('allow_plus_one',0, true, 'details') : ''"><close-icon></close-icon> No</span>
                                </div>
                                <input @change="updateForm('details','allow_plus_one',false)" type="hidden" v-model="orderinfo.allow_plus_one" id="allow_plus_one" class="form-control allow_plus_one">
                            </div>
                        </div>

                        <div class="form-group-beside row" :class="errorObject['details']['other_groups_invited'] ? 'required' : ''" v-if="orderinfo.type != 'Virtual'">
                            <label for="other_groups_invited" class="col-2 col-md-6">
                                Will other groups or guilds be invited? <em class="yellow no-style">*</em>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <div class="toggle-js">
                                    <span class="yes-toggle" :class="toggleClass['other_groups_invited']" @click="update_allowed['details'] ? toggleFunctionality('other_groups_invited','1', true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                    <span class="no-toggle"  :class="toggleClass['other_groups_invited']" @click="update_allowed['details'] ? toggleFunctionality('other_groups_invited','0', true, 'details') : ''"><close-icon></close-icon> No</span>
                                    <span class="maybe-toggle" :class="toggleClass['other_groups_invited']" @click="update_allowed['details'] ? toggleFunctionality('other_groups_invited','maybe', true, 'details') : ''"><question></question> Maybe</span>
                                </div>
                                <input @change="updateForm('details','other_groups_invited',false)" type="hidden" v-model="orderinfo.other_groups_invited" id="other_groups_invited" class="form-control other_groups_invited">
                            </div>
                        </div>

                        <div class="form-group-beside row ml-3" :class="errorObject['details']['other_groups_invited_details'] ? 'required' : ''" v-if="orderinfo.other_groups_invited == 1">
                            <label for="other_groups_invited_details" class="col-2 col-md-6">
                                What groups or guilds are invited? <em class="yellow no-style">*</em>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <textarea rows=3 @change="updateForm('details','other_groups_invited_details',false)" v-model="orderinfo.other_groups_invited_details" id="other_groups_invited_details" class="form-control venue_name" :disabled="(role == 'viewer')?true:false"/>
                            </div>
                        </div>

                        <div class="form-group-beside row ml-3" :class="errorObject['details']['other_groups_invited_seats'] ? 'required' : ''" v-if="orderinfo.other_groups_invited == 1">
                            <label for="other_groups_invited_seats" class="col-2 col-md-6">
                                How many seats will be reserved for these groups or guilds? <em class="yellow no-style">*</em>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details','other_groups_invited_seats',false)" type="text" v-model="orderinfo.other_groups_invited_seats" id="other_groups_invited_seats" class="form-control venue_name" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>

                        <div class="form-group-beside row ml-3" :class="errorObject['details']['other_groups_invited_reserved'] ? 'required' : ''" v-if="orderinfo.other_groups_invited == 1">
                            <label for="other_groups_invited_details" class="col-2 col-md-6">
                                Will there be a reserved section for Television Academy members or seating mixed into the audience? <em class="yellow no-style">*</em>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('details','other_groups_invited_reserved',false)" type="text" v-model="orderinfo.other_groups_invited_reserved" id="other_groups_invited_reserved" class="form-control venue_name" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>

                        <div class="form-group-beside row ml-3" :class="errorObject['details']['other_groups_invited_special_line'] ? 'required' : ''" v-if="orderinfo.other_groups_invited == 1">
                            <label for="other_groups_invited_special_line" class="col-2 col-md-6">
                                Is there a special line for the groups or guilds?  <em class="yellow no-style">*</em>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <div class="toggle-js">
                                    <span class="yes-toggle" :class="toggleClass['other_groups_invited_special_line']" @click="update_allowed['details'] ? toggleFunctionality('other_groups_invited_special_line',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                    <span class="no-toggle"  :class="toggleClass['other_groups_invited_special_line']" @click="update_allowed['details'] ? toggleFunctionality('other_groups_invited_special_line',0, true, 'details') : ''"><close-icon></close-icon> No</span>
                                </div>
                                <input @change="updateForm('details','other_groups_invited_special_line',false)" type="hidden" v-model="orderinfo.other_groups_invited_special_line" id="other_groups_invited_special_line" class="form-control other_groups_invited_special_line">

                            </div>
                        </div>

                        <div class="form-group-beside row" :class="errorObject['details']['checkin_process_notes'] ? 'required' : ''" v-if="orderinfo.type != 'Virtual'">
                            <label for="checkin_process_notes" class="col-2 col-md-6">Additional check-in process notes:</label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <textarea @change="updateForm('details','checkin_process_notes',false)" rows="5" type="text" id="checkin_process_notes" class="form-control" v-model="orderinfo.checkin_process_notes" :disabled="(role == 'viewer')?true:false"></textarea>
                            </div>
                        </div>

                        <div class="form-group-beside row" :class="errorObject['details']['health_protocols'] ? 'required' : ''" v-if="orderinfo.type != 'Virtual'">
                            <label for="health_protocols" class="col-2 col-md-6">COVID/Health Protocols:</label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <textarea @change="updateForm('details','health_protocols',false)" rows="5" type="text" id="health_protocols" class="form-control" v-model="orderinfo.health_protocols" :disabled="(role == 'viewer')?true:false"></textarea>
                            </div>
                        </div>
                    </section>

                    <section v-if="orderinfo.type != 'Virtual'">
                        <h3 class="order-header mt-5">Location Information</h3>
                        <div class="ml-4 mt-2 description instructions-text"><p>Make your selection then complete the additional fields.</p></div>

                        <div class="row radio-buttons-toggle-wrapper mt-5 mb-5" :class="errorObject['details']['wolf_theatre'] ? 'required' : ''">
                            <div class="col-12 col-md-6 instructions-text">
                                Will you be using the Wolf Theatre? <em class="yellow no-style">*</em>
                                <div class="description f-14 pr-5">
                                    <p>The Television Academy's Saban Media Center houses the state-of-the-art 591-seat Wolf Theater, available for FYC bookings. <a href="https://www.emmys.com/academy/saban-media-center" target="_blank">Click here for details</a>.</p>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 d-flex align-items-center justify-content-between mt-2">
                                <div class="custom-control custom-radio d-flex align-items-center pl-0 pr-4">
                                    <input v-if="update_allowed['details']" @change="updateForm('details','wolf_theatre',false)" type="radio" v-model="orderinfo.wolf_theatre" value="1" class="custom-control-input" id="orderinfo.wolf_theatre" name="orderinfo.wolf_theatre">
                                    <div v-else>
                                        <circle-filled-icon v-if="orderinfo.wolf_theatre == '1'"></circle-filled-icon>
                                        <circle_icon v-else></circle_icon>
                                    </div>
                                    <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.wolf_theatre">Yes</label>
                                </div>
                                <div class="custom-control custom-radio d-flex align-items-center pl-0">
                                    <input v-if="update_allowed['details']" @change="updateForm('details','wolf_theatre',false)" type="radio" v-model="orderinfo.wolf_theatre" value="0" class="custom-control-input" id="orderinfo.wolf_theatre_no" name="orderinfo.wolf_theatre">
                                    <div v-else>
                                        <circle-filled-icon v-if="orderinfo.wolf_theatre == '0'"></circle-filled-icon>
                                        <circle_icon v-else></circle_icon>
                                    </div>
                                    <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.wolf_theatre_no">No</label>
                                </div>
                            </div>
                        </div>

                        <div class="location-wrap mt-4" v-show="orderinfo.wolf_theatre == 1">
                            <div class="description pl-3 pb-1">
                                <p>Additional forms required for booking the Wolf Theatre can be found here: <a href="https://emmys.com/fyc/booking/wolf-theatre" target="_blank" rel="noopener noreferrer">Wolf Theatre Form</a></p>
                            </div>

                            <div class="form-group-beside row" v-if="role == 'admin'">
                                <label for="venue_capacity" class="col-2 col-md-6">
                                    Venue Capacity: <em class="yellow no-style">*</em>
                                    <div class="description">
                                        <p>Actual number of seats provided.</p>
                                    </div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','venue_capacity',false)" type="text" v-model="orderinfo.venue_capacity" id="venue_capacity" class="form-control venue_capacity" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                            <div class="form-group-beside row" v-if="role == 'admin'">
                                <label for="total_max_registrations" class="col-2 col-md-6">
                                    Total Max Registrations:
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','total_max_registrations',false)" type="text" v-model="orderinfo.total_max_registrations" id="total_max_registrations" class="form-control total_max_registrations" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>
                        </div>

                        <div class="location-wrap mt-4" v-show="orderinfo.wolf_theatre == 0">
                            <h4 class="mb-0 f-400">Venue Details</h4>
                            <div class="form-group-beside row" :class="errorObject['details']['venue_name'] ? 'required' : ''">
                                <label for="venue_name" class="col-2 col-md-6">Venue Name: <em class="yellow no-style">*</em></label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','venue_name',false)" type="text" v-model="orderinfo.venue_name" id="venue_name" class="form-control venue_name" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['ada_venue_link'] ? 'required' : ''">
                                <label for="ada_venue_link" class="col-2 col-md-6">Venue Link: <em class="yellow no-style">*</em></label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','ada_venue_link',false)" type="text" v-model="orderinfo.ada_venue_link" id="ada_venue_link" class="form-control ada_venue_link" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['venue_address.street_1'] && errorObject['details']['venue_address.street_1'] ? 'required' : ''">
                                <label for="venue_address" class="col-2 col-md-6">Street 1: <em class="yellow no-style">*</em></label>
                                <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.venue_address != null">
                                    <input @change="updateForm('details','venue_address.street_1',false)" rows="5" type="text" id="venue_address" class="form-control" v-model="orderinfo.venue_address.street_1" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>
                            <div class="form-group-beside row" :class="errorObject['details']['venue_address.street_2'] && errorObject['details']['venue_address.street_2'] ? 'required' : ''">
                                <label for="venue_street_2" class="col-2 col-md-6">Street 2:</label>
                                <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.venue_address != null">
                                    <input @change="updateForm('details','venue_address.street_2',false)" rows="5" type="text" id="venue_street_2" class="form-control" v-model="orderinfo.venue_address.street_2" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>
                            <div class="form-group-beside row" :class="errorObject['details']['venue_address.city'] && errorObject['details']['venue_address.city'] ? 'required' : ''">
                                <label for="venue_address_city" class="col-2 col-md-6">City: <em class="yellow no-style">*</em></label>
                                <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.venue_address != null">
                                    <input @change="updateForm('details','venue_address.city',false)" rows="5" type="text" id="venue_address_city" class="form-control" v-model="orderinfo.venue_address.city" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>
                            <div class="form-group-beside row" :class="errorObject['details']['venue_address.state'] && errorObject['details']['venue_address.state'] ? 'required' : ''">
                                <label for="venue_address_state" class="col-2 col-md-6">State: <em class="yellow no-style">*</em></label>
                                <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.venue_address != null">
                                    <v-select v-if="update_allowed['details']" id="venue_address['state']" placeholder="Select option" v-model="orderinfo.venue_address.state" :options="states" @input="updateForm('details','venue_address.state',false)"></v-select>
                                    <span class="readonly pl-10" v-text="orderinfo.venue_address['state']" v-if="!update_allowed['details']"></span>
                                </div>
                            </div>
                            <div class="form-group-beside row" :class="errorObject['details']['venue_address.postal_code'] && errorObject['details']['venue_address.postal_code'] ? 'required' : ''">
                                <label for="venue_address_postal_code" class="col-2 col-md-6">ZIP Code: <em class="yellow no-style">*</em></label>
                                <div class="col-10 col-md-6 input-wrapper" v-if="orderinfo.venue_address != null">
                                    <input @change="updateForm('details','venue_address.postal_code',false)" rows="5" type="text" id="venue_address_postal_code" class="form-control" v-model="orderinfo.venue_address.postal_code" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                            <div v-if="role === 'admin'">
                                <label>Google Maps Link (Admin only)</label>
                                <input type="text" v-model="orderinfo.venue_address.google_maps_link">
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['parking_details'] ? 'required' : ''">
                                <label for="parking_details" class="col-2 col-md-6">
                                    Parking Details: <em class="yellow no-style">*</em>
                                    <div class="description">
                                        Include address for parking if different from venue, details about complimentary/paid, time that the parking opens and closes.
                                    </div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <textarea @change="updateForm('details','parking_details',false)" rows="3" type="text" id="parking_details" class="form-control" v-model="orderinfo.parking_details" :disabled="(role == 'viewer')?true:false"></textarea>
                                </div>
                            </div>


                            <div class="form-group-beside row" v-if="role == 'admin'">
                                <label for="tva_member_seats" class="col-2 col-md-6">
                                    Seats for Television Academy members: <em class="yellow no-style">*</em>
                                    <div class="description">
                                        <p>Number of seats available to Television Academy members.</p>
                                    </div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','tva_member_seats',false)" type="text" v-model="orderinfo.tva_member_seats" id="tva_member_seats" class="form-control tva_member_seats" :disabled="(role == 'viewer')">
                                </div>
                            </div>

                            <div class="form-group-beside row" v-if="role == 'admin'">
                                <label for="holdback_seats" class="col-2 col-md-6">
                                    Holdback seats: <em class="yellow no-style">*</em>
                                    <div class="description">
                                        <p>Number of holdback seats for VIPs, Press, etc.</p>
                                    </div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','holdback_seats',false)" type="text" v-model="orderinfo.holdback_seats" id="holdback_seats" class="form-control holdback_seats" :disabled="(role == 'viewer')">
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['venue_capacity'] ? 'required' : ''">
                                <label for="venue_capacity" class="col-2 col-md-6">
                                    Venue Capacity: <em class="yellow no-style">*</em>
                                    <div class="description">
                                        <p>Actual number of seats provided.</p>
                                    </div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','venue_capacity',false)" type="text" v-model="orderinfo.venue_capacity" id="venue_capacity" class="form-control venue_capacity" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['total_max_registrations'] ? 'required' : ''" v-if="role == 'admin'">
                                <label for="total_max_registrations" class="col-2 col-md-6">
                                    Total Max Registrations:
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','total_max_registrations',false)" type="text" v-model="orderinfo.total_max_registrations" id="total_max_registrations" class="form-control total_max_registrations" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['standby_line'] ? 'required' : ''">
                                <label for="standby_line" class="col-2 col-md-6">
                                    Will you allow a stand-by line for the event? <em class="yellow no-style">*</em>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <div class="toggle-js">
                                        <span class="yes-toggle" :class="toggleClass['standby_line']" @click="update_allowed['details'] ? toggleFunctionality('standby_line',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                        <span class="no-toggle" :class="toggleClass['standby_line']" @click="update_allowed['details'] ? toggleFunctionality('standby_line',0, true, 'details') : ''"><close-icon></close-icon> No</span>
                                    </div>
                                    <input @change="updateForm('details','standby_line',false)" type="hidden" v-model="orderinfo.standby_line" id="standby_line" class="form-control standby_line">
                                </div>
                            </div>

                            <h3 class="order-header mt-5">ADA Accessibility Accommodations Provided at Venue</h3>

                            <div class="form-group-beside row" :class="errorObject['details']['ada_contact'] ? 'required' : ''">
                                <label for="ada_contact" class="col-2 col-md-6">
                                    ADA / Accessibility Contact: <em class="yellow no-style">*</em>
                                    <div class="description">Partner to provide contact email for members to reach out if they have questions or accessibility requests/concerns.</div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','ada_contact',false)" type="text" v-model="orderinfo.ada_contact" id="ada_contact" class="form-control venue_name" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>

                            <h4 class="mb-2 mt-4">Parking/Venue Access</h4>
                            <div class="form-group-beside row mt-0" :class="errorObject['details']['ada_parking_spaces'] ? 'required' : ''">
                                <label for="ada_parking_spaces" class="col-2 col-md-6">
                                    Please provide the location/specific directions and number of accessible parking spaces: <em class="yellow no-style">*</em>
                                    <div class="description">Ex: There are nine designated accessible parking spaces available in the structure just east of the venue. Parking in this lot is available upon a first-come, first-served basis. A valid, state-issued ADA placard or license plate is required for accessible parking.</div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <textarea @change="updateForm('details','ada_parking_spaces',false)" rows="3" type="text" id="ada_parking_spaces" class="form-control" v-model="orderinfo.ada_parking_spaces" :disabled="(role == 'viewer')?true:false"></textarea>
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['ada_parking_ramp_details'] ? 'required' : ''">
                                <label for="ada_parking_ramp_details" class="col-2 col-md-6">
                                    Are there elevators/ramp access to the venue? <em class="yellow no-style">*</em>
                                    <div class="description">Ex: Elevator-accessible parking is available on the 3rd and 4th levels. The 2nd level and roof level do not have elevator access.</div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <textarea @change="updateForm('details','ada_parking_ramp_details',false)" rows="3" type="text" id="ada_parking_ramp_details" class="form-control" v-model="orderinfo.ada_parking_ramp_details" :disabled="(role == 'viewer')?true:false"></textarea>
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['ada_parking_additional_info'] ? 'required' : ''">
                                <label for="ada_parking_additional_info" class="col-2 col-md-6">
                                    Additional parking information:
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <textarea @change="updateForm('details','ada_parking_additional_info',false)" rows="3" type="text" id="ada_parking_additional_info" class="form-control" v-model="orderinfo.ada_parking_additional_info" :disabled="(role == 'viewer')?true:false"></textarea>
                                </div>
                            </div>

                            <h4 class="mb-2 mt-4">Assistive Listening</h4>
                            <div class="form-group-beside row" :class="errorObject['details']['ada_listening_devices'] ? 'required' : ''">
                                <label for="ada_listening_devices" class="col-2 col-md-6">
                                    Does the venue have assistive listening devices? <em class="yellow no-style">*</em>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <div class="toggle-js">
                                        <span class="yes-toggle" :class="toggleClass['ada_listening_devices']" @click="update_allowed['details'] ? toggleFunctionality('ada_listening_devices',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                        <span class="no-toggle" :class="toggleClass['ada_listening_devices']" @click="update_allowed['details'] ? toggleFunctionality('ada_listening_devices',0, true, 'details') : ''"><close-icon></close-icon> No</span>
                                    </div>
                                    <input @change="updateForm('details','ada_listening_devices',false)" type="hidden" v-model="orderinfo.ada_listening_devices" id="ada_listening_devices" class="form-control ada_listening_devices">
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['ada_listening_closed_captioning'] ? 'required' : ''">
                                <label for="ada_listening_closed_captioning" class="col-2 col-md-6">
                                    Will the event include closed captioning for live-streamed video? <em class="yellow no-style">*</em>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <div class="toggle-js">
                                        <span class="yes-toggle" :class="toggleClass['ada_listening_closed_captioning']" @click="update_allowed['details'] ? toggleFunctionality('ada_listening_closed_captioning','1', true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                        <span class="no-toggle" :class="toggleClass['ada_listening_closed_captioning']" @click="update_allowed['details'] ? toggleFunctionality('ada_listening_closed_captioning','0', true, 'details') : ''"><close-icon></close-icon> No</span>
                                        <span class="na-toggle" :class="toggleClass['ada_listening_closed_captioning']" @click="update_allowed['details'] ? toggleFunctionality('ada_listening_closed_captioning','n/a', true, 'details') : ''"><minus-large></minus-large> N/A</span>
                                    </div>
                                    <input @change="updateForm('details','ada_listening_closed_captioning',false)" type="hidden" v-model="orderinfo.ada_listening_closed_captioning" id="ada_listening_closed_captioning" class="form-control ada_listening_closed_captioning">
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['ada_listening_adl_interpreter'] ? 'required' : ''">
                                <label for="ada_listening_adl_interpreter" class="col-2 col-md-6">
                                    Will the event have an ASL interpreter? <em class="yellow no-style">*</em>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <div class="toggle-js">
                                        <span class="yes-toggle" :class="toggleClass['ada_listening_adl_interpreter']" @click="update_allowed['details'] ? toggleFunctionality('ada_listening_adl_interpreter',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                        <span class="no-toggle" :class="toggleClass['ada_listening_adl_interpreter']" @click="update_allowed['details'] ? toggleFunctionality('ada_listening_adl_interpreter',0, true, 'details') : ''"><close-icon></close-icon> No</span>
                                    </div>
                                    <input @change="updateForm('details','ada_listening_adl_interpreter',false)" type="hidden" v-model="orderinfo.ada_listening_adl_interpreter" id="ada_listening_adl_interpreter" class="form-control ada_listening_adl_interpreter">
                                </div>
                            </div>

                            <h4 class="mb-2 mt-4">Accessible Seating</h4>
                            <div class="form-group-beside row mt-0"  :class="errorObject['details']['ada_seating_locations'] ? 'required' : ''">
                                <label for="ada_seating_locations" class="col-2 col-md-6">
                                    Please provide the location/specific directions and number of accessible seats: <em class="yellow no-style">*</em>
                                    <div class="description">Ex: There are six wheelchair-accessible seats or positions in the cross aisle, two in the front row and three in the back row. The front and back row seats are accessible by elevator.</div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <textarea @change="updateForm('details','ada_seating_locations',false)" rows="3" type="text" id="ada_seating_locations" class="form-control" v-model="orderinfo.ada_seating_locations" :disabled="(role == 'viewer')?true:false"></textarea>
                                </div>
                            </div>

                            <div class="form-group-beside row"  :class="errorObject['details']['ada_wheelchair_ramp'] ? 'required' : ''">
                                <label for="ada_wheelchair_ramp" class="col-2 col-md-6">
                                    Are there wheelchair ramps to enter and use around the venue? <em class="yellow no-style">*</em>
                                    <div class="description">Ex: One ramp is located at the exit of the parking structure, at the front of the Saban Media Center. The ramp to the Plaza is located toward the west side of the headquarters building; at events with a red carpet, this ramp serves as a drop-off point providing access to the carpet for those using it.</div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <textarea @change="updateForm('details','ada_wheelchair_ramp',false)" rows="3" type="text" id="ada_wheelchair_ramp" class="form-control" v-model="orderinfo.ada_wheelchair_ramp" :disabled="(role == 'viewer')?true:false"></textarea>
                                </div>
                            </div>

                            <div class="form-group-beside row" :class="errorObject['details']['ada_wheelchair_lift'] ? 'required' : ''">
                                <label for="ada_wheelchair_lift" class="col-2 col-md-6">
                                    Is there a wheelchair lift for stage/venue access if needed? <em class="yellow no-style">*</em>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <div class="toggle-js">
                                        <span class="yes-toggle" :class="toggleClass['ada_wheelchair_lift']" @click="update_allowed['details'] ? toggleFunctionality('ada_wheelchair_lift','1', true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                        <span class="no-toggle" :class="toggleClass['ada_wheelchair_lift']" @click="update_allowed['details'] ? toggleFunctionality('ada_wheelchair_lift','0', true, 'details') : ''"><close-icon></close-icon> No</span>
                                        <span class="na-toggle" :class="toggleClass['ada_wheelchair_lift']" @click="update_allowed['details'] ? toggleFunctionality('ada_wheelchair_lift','n/a', true, 'details') : ''"><minus-large></minus-large> N/A</span>
                                    </div>
                                    <input @change="updateForm('details','ada_wheelchair_lift',false)" type="hidden" v-model="orderinfo.ada_wheelchair_lift" id="ada_wheelchair_lift" class="form-control ada_wheelchair_lift">
                                </div>
                            </div>

                            <h4 class="mb-2 mt-4">Accessible Restrooms</h4>
                            <div class="form-group-beside row mt-0"  :class="errorObject['details']['ada_restrooms'] ? 'required' : ''">
                                <label for="ada_restrooms" class="col-2 col-md-6">
                                    Please provide the location/specific directions and number of accessible restrooms? <em class="yellow no-style">*</em>
                                    <div class="description">Ex: Accessible restrooms are located on the west side of the lobby.  Restrooms on the 2nd level are not accessible.</div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <textarea @change="updateForm('details','ada_restrooms',false)" rows="3" type="text" id="ada_restrooms" class="form-control" v-model="orderinfo.ada_restrooms" :disabled="(role == 'viewer')?true:false"></textarea>
                                </div>
                            </div>

                            <div class="form-group-beside row"  :class="errorObject['details']['ada_accommodations_other'] ? 'required' : ''">
                                <label for="ada_accommodations_other" class="col-2 col-md-6">Other Accommodations:</label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <textarea @change="updateForm('details','ada_accommodations_other',false)" rows="3" type="text" id="ada_accommodations_other" class="form-control" v-model="orderinfo.ada_accommodations_other" :disabled="(role == 'viewer')?true:false"></textarea>
                                </div>
                            </div>

                            <h3 class="mt-4 order-header">Rideshare Vouchers</h3>
                            <div class="form-group-beside row" :class="errorObject['details']['ride_share_vouchers'] ? 'required' : ''">
                                <label for="ride_share_vouchers" class="col-2 col-md-6">
                                    Are you offering rideshare vouchers? <em class="yellow no-style">*</em>
                                    <div class="description">
                                        Value is limited to $50/voucher. Please email rideshare voucher codes in an excel or csv file, to <a href="mailto:fyc@televisionacademy.com" target="_blank" rel="noopener noreferrer">fyc@televisionacademy.com</a>.
                                    </div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <div class="toggle-js">
                                        <span class="yes-toggle" :class="toggleClass['ride_share_vouchers']" @click="update_allowed['details'] ? toggleFunctionality('ride_share_vouchers',1, true, 'details') : ''"><checkmark></checkmark> Yes</span>
                                        <span class="no-toggle"  :class="toggleClass['ride_share_vouchers']" @click="update_allowed['details'] ? toggleFunctionality('ride_share_vouchers',0, true, 'details') : ''"><close-icon></close-icon> No</span>
                                    </div>
                                    <input type="hidden" v-model="orderinfo.ride_share_vouchers" id="ride_share_vouchers" class="form-control ride_share_vouchers">
                                </div>
                            </div>

                            <div class="form-group-beside row ml-3" :class="errorObject['details']['ride_share_amount'] ? 'required' : ''" v-if="orderinfo.ride_share_vouchers == 1">
                                <label for="ride_share_amount" class="col-2 col-md-6">
                                    Number of rideshare vouchers provided: <em class="yellow no-style">*</em>
                                    <div class="description">Value is limited to $50/voucher.</div>
                                </label>
                                <div class="col-10 col-md-6 input-wrapper">
                                    <input @change="updateForm('details','ride_share_amount',false)" type="text" v-model="orderinfo.ride_share_amount" id="ride_share_amount" class="form-control venue_name" :disabled="(role == 'viewer')?true:false">
                                </div>
                            </div>
                        </div>
                    </section>

                    <section v-if="orderinfo.type != 'In-Person'">
                        <h3 class="order-header mt-5">Virtual Event Streaming</h3>

                        <div class="radio-buttons-toggle-wrapper mb-4" :class="errorObject['details']['platform_used'] ? 'required' : ''">
                            <div class="mt-4 ml-2">Where will this event be presented? <em class="yellow no-style">*</em></div>
                            <div class="ml-4 pb-2 mt-4 col-12 col-md-9 d-flex align-items-center justify-content-between mt-2">
                                <div class="custom-control custom-radio d-flex align-items-center pl-0 pr-5">
                                    <input v-if="update_allowed['details']" @change="updateForm('details','platform_used',false)" type="radio" v-model="orderinfo.platform_used" value="TVA Viewing Platform" class="custom-control-input" id="orderinfo.platform_used" name="orderinfo.platform_used">
                                    <div v-else>
                                        <circle-filled-icon v-if="orderinfo.platform_used == 'TVA Viewing Platform'"></circle-filled-icon>
                                        <circle_icon v-else></circle_icon>
                                    </div>
                                    <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.platform_used">
                                        Presented on Television Academy Viewing Platform
                                    </label>
                                </div>
                                <div class="custom-control custom-radio d-flex align-items-center pl-0">
                                    <input v-if="update_allowed['details']" @change="updateForm('details','platform_used',false)" type="radio" v-model="orderinfo.platform_used" value="Own Platform" class="custom-control-input" id="orderinfo.platform_used_no" name="orderinfo.platform_used">
                                    <div v-else>
                                        <circle-filled-icon v-if="orderinfo.platform_used == 'Own Platform'"></circle-filled-icon>
                                        <circle_icon v-else></circle_icon>
                                    </div>
                                    <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.platform_used_no">
                                        Presented on own Platform
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="virtual-question-wrapper" v-if="orderinfo.platform_used != null">

                            <div class="wrapper" v-if="orderinfo.platform_used == 'Own Platform'">
                                <div class="form-group-beside row" :class="errorObject['details']['event_url'] ? 'required' : ''">
                                    <label for="event_url" class="col-2 col-md-6">
                                        URL for your FYC Event: <em class="yellow no-style">*</em>
                                        <div class="description">https://example.com</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','event_url', false)" type="text" v-model="orderinfo.event_url" id="event_url" class="form-control event_url" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>

                                <div class="radio-buttons-toggle-wrapper mb-4" :class="errorObject['details']['is_code_unique'] ? 'required' : ''">
                                    <div class="mt-4 ml-2">Is the event password unique for each member? <em class="yellow no-style">*</em></div>
                                    <div class="ml-4 pb-2 mt-4 col-12 col-md-9 d-flex align-items-center justify-content-between mt-2">
                                        <div class="custom-control custom-radio d-flex align-items-center pl-0 pr-5">
                                            <input v-if="update_allowed['details']" @change="updateForm('details','is_code_unique',false)" type="radio" v-model="orderinfo.is_code_unique" value="0" class="custom-control-input" id="orderinfo.is_code_unique_no" name="orderinfo.is_code_unique">
                                            <div v-else>
                                                <circle-filled-icon v-if="orderinfo.is_code_unique == '0'"></circle-filled-icon>
                                                <circle_icon v-else></circle_icon>
                                            </div>
                                            <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.is_code_unique_no">
                                                No - one code will work for all
                                            </label>
                                        </div>
                                        <div class="custom-control custom-radio d-flex align-items-center pl-0">
                                            <input v-if="update_allowed['details']" @change="updateForm('details','is_code_unique',false)" type="radio" v-model="orderinfo.is_code_unique" value="1" class="custom-control-input" id="orderinfo.is_code_unique_yes" name="orderinfo.is_code_unique">
                                            <div v-else>
                                                <circle-filled-icon v-if="orderinfo.is_code_unique == '1'"></circle-filled-icon>
                                                <circle_icon v-else></circle_icon>
                                            </div>
                                            <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo.is_code_unique_yes">
                                                Yes - each member will have a unique code
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="" v-show="orderinfo.is_code_unique == '1'">
                                    <div class="form-group-beside" :class="errorObject['details']['event_code_upload'] ? 'required' : ''">
                                        <a v-if="orderinfo.event_code_upload" :href="orderinfo.event_code_upload_full_url" class="btn btn-primary float-right" download><download class="svg-black"></download> Download</a>
                                        <file-upload
                                            display_image_or_filename="file"
                                            file_types=".csv, .xls, .xlsx"
                                            :entry="orderinfo"
                                            machine_name="event_code_upload"
                                            description="You must upload 30,000 codes. These will be assigned to the Television Academy membership and distributed in your FYC email. File must be in .csv, .xls or .xlsx format."
                                            display_name="Upload Codes:"
                                            required="true"
                                            :saved_filename="orderinfo.event_code_upload"
                                            view_url=""
                                            :update_allowed="update_allowed['details']"
                                            role="role"
                                            :action_url="'/event/' + orderinfo.order_id"
                                            @saveForValidation="fileSaveForValidation">
                                        </file-upload>
                                    </div>
                                </div>

                                <div v-if="orderinfo.is_code_unique == '0'" class="form-group-beside row" :class="errorObject['details']['event_code'] ? 'required' : ''">
                                    <label for="event_code" class="col-2 col-md-6">
                                        Event Code: <em class="yellow no-style">*</em>
                                        <div class="description">Enter the code used for all members - no import file needed.</div>
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','event_code',false)" type="text" v-model="orderinfo.event_code" id="event_code" class="form-control event_url" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>
                            </div>

                            <div class="wrapper" v-if="orderinfo.platform_used == 'TVA Viewing Platform'">

                                <div class="row form-group-beside radio-wrapper" :class="errorObject['details'] && errorObject['details']['livestream'] ? 'required' : ''">
                                    <div class="col-12 col-md-6 radio-button-wrapper-forspacing">
                                        Is this livestreamed or pretaped? <em class="yellow no-style">*</em>
                                        <div class="description">
                                            If the event is livestreamed there is an additional fee of $2,500.
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-5 d-flex align-items-center justify-content-between mt-2 mb-1" :class="update_allowed['details'] ? 'pl-5' : ''">
                                        <div class="custom-control custom-radio d-flex align-items-center pl-0 pr-4">
                                            <input v-if="update_allowed['details']" @change="updateForm('details','livestream',false)" type="radio" v-model="orderinfo.livestream" value="1" class="custom-control-input" id="orderinfo_livestream" name="orderinfo.livestream">
                                            <div v-else>
                                                <circle-filled-icon v-if="orderinfo.livestream == '1'"></circle-filled-icon>
                                                <circle_icon v-else></circle_icon>
                                            </div>
                                            <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo_livestream">Livestream</label>
                                        </div>

                                        <div class="custom-control custom-radio d-flex align-items-center pl-0">
                                            <input v-if="update_allowed['details']" @change="updateForm('details','livestream',false)" type="radio" v-model="orderinfo.livestream" value="0" class="custom-control-input" id="orderinfo_vod" name="orderinfo.livestream">
                                            <div v-else>
                                                <circle-filled-icon v-if="orderinfo.livestream == '0'"></circle-filled-icon>
                                                <circle_icon v-else></circle_icon>
                                            </div>
                                            <label class="custom-control-label no-border pl-1" :class="update_allowed['details'] ? '' : 'no-click'" for="orderinfo_vod">Pretaped</label>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="orderinfo.livestream == '1'" class="form-group-beside row" :class="errorObject['details']['embed_code'] ? 'required' : ''">
                                    <label for="embed_code" class="col-2 col-md-6">
                                        Embed Code:
                                    </label>
                                    <div class="col-10 col-md-6 input-wrapper">
                                        <input @change="updateForm('details','embed_code',false)" type="text" v-model="orderinfo.embed_code" id="embed_code" class="form-control event_url" :disabled="(role == 'viewer')?true:false">
                                    </div>
                                </div>

                                <div v-if="orderinfo.livestream == '0'" :class="errorObject['details'] && errorObject['details']['video_virtual_event.0.filename'] ? 'required' : ''">
                                    <div>
                                        <video-component-upload ref="videoComponent_VirtualEvent"
                                                                :role="role"
                                                                :updateallowed="update_allowed['details']"
                                                                :initialdata="[]"
                                                                :isadmin="role==='admin'"
                                                                :videoobject="orderinfo.video_virtual_event"
                                                                :bucketname="bucketname"
                                                                :entryid="orderinfo.id"
                                                                :nominee_video_status="orderinfo.nominee_video_status"
                                                                :submitterid="orderinfo.user_id"
                                                                :add_another_video_allowed="true"
                                                                :videofilestatuses="videofilestatuses"
                                                                machine_name="video_virtual_event"
                                                                type="event"
                                        ></video-component-upload>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </section>

                    <section class="review-notes mt-5">
                        <h2>Acknowledgement</h2>
                        <div class="acknowledgement-description wysiwyg-content" v-html="events_acknowledgement"></div>

                        <div class="signature-block" @click.prevent="signatureNeededPopover()">
                            <div class="btn btn-primary btn-big" v-show="!orderinfo.signature" v-if="update_allowed['details']">
                                <checkbox_empty></checkbox_empty> Click to sign
                            </div>
                            <img :src="orderinfo.signature" />
                        </div>
                        <div class="red warning" v-show="!orderinfo.signature">Order can only be submitted after all of the required information has been completed and the acknowledgement has been signed.</div>
                    </section>

                    <div class="text-right">
                        <button @click.prevent="SaveApplicationStep(1)" v-if="update_allowed['details'] && role != 'admin'" v-bind:disabled="!orderinfo.signature || updatingForm" type="submit" name="action" value="next" class="btn btn-primary btn-step-next" id="NextButton">
                            <div>
                                <span class="submit-text">Approve Event Details</span>
                                <span>& Submit For Review</span>
                            </div>
                            <right-small></right-small>
                        </button>

                        <a v-if="role === 'admin'" @click.prevent="updateForm('', '', true)" type="submit" name="action" value="next" class="btn btn-primary btn-step-next" id="NextButton">
                            <save-icon></save-icon>
                            <div>
                                <span class="submit-text">Save Order</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item accordion-contact-details-wrapper mb-3" id="order-step-1">
            <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between">
                <a @click.prevent="applicationStepSelector(1)" class="w-100 header-button position-relative d-flex justify-content-between accordion-button pl-30 pr-30 py-3 py-lg-2" :class="dynamicClass(1)">
                    <div class="position-absolute app-status-icons">
                        <Attention svg-class="svg-24 red-svg" v-if="iconArray.assets && iconArray.assets == 'incomplete'"></Attention>
                        <checkmark svg-class="svg-24 svg-green" v-if="iconArray.assets && iconArray.assets == 'complete'"></checkmark>
                    </div>
                    <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header">
                        <h3 class="mb-0">Event Assets</h3>
                        <div class="d-flex align-items-center w-25" :class="statusForIndicatorsAssets.includes(orderinfo.asset_status) ? 'justify-content-between' : 'justify-content-end'">
                            <div class="d-flex align-items-center cursor-initial" :class="'pr-2 status-color-' + orderinfo.asset_status" v-if="statusForIndicatorsAssets.includes(orderinfo.asset_status)">
                                <submitted svg-class="svg-24 mr-2" v-if="orderinfo.asset_status == 'submitted'"></submitted>
                                <question svg-class="svg-24 mr-2" v-if="orderinfo.asset_status == 'need_more_info'"></question>
                                <circle_check svg-class="svg-24 mr-2" v-if="orderinfo.asset_status == 'approved' || orderinfo.asset_status == 'building' || orderinfo.asset_status == 'build_complete' || orderinfo.asset_status == 'published'"></circle_check>
                                <attention svg-class="svg-24 mr-2 svg-accent" v-if="orderinfo.asset_status == 'internal_review'"></attention>
                                <span class="text-uppercase">{{ orderinfo.asset_status.replace(/_/g, ' ') }}</span>
                            </div>
                            <up-chevron-icon class="svg-accent" :class="(orderinfo.application_step == 1) ? 'rotate-0' : 'rotate-180'"></up-chevron-icon>
                        </div>
                    </div>
                </a>
            </div>
            <div id="accordion-order-assets" class="pt-3 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="orderinfo.application_step == 1 ? 'show' : ''">
                <div class="text-right red" v-if="orderinfo.assets_needs_more_info_text && update_allowed['assets'] && role != 'admin'">
                    {{ orderinfo.assets_needs_more_info_text }}
                </div>
                <div class="full-instructions-wrapper fields-wrapper-row pl-30">
                    <h3 class="order-header">Email and Key Art Assets and Styles</h3>

                    <h3 class="order-header mt-5 mb-2" v-if="role != 'admin'">Graphics</h3>
                    Please read and acknowledge the <a href="#" @click.prevent="graphics_acknowledgment_text_modal = true;">FYC Partner Graphic Requirements</a> before uploading assets.

                    <div class="form-group-beside row mt-4" :class="errorObject['assets'] && errorObject['assets']['graphic_requirements'] ? 'required' : ''">
                        <label for="graphic_requirements" class="col-2 col-md-6">
                            I have read the graphic requirements. <em class="yellow no-style">*</em>
                        </label>
                        <div class="col-10 col-md-6 input-wrapper">
                            <div class="checkout-checkbox-wrapper">
                                <input v-if="update_allowed['assets']" @click="$scrollTo('#accordion-order-assets', 700);" @change="updateForm('assets','graphic_requirements',false)" type="checkbox" v-model="orderinfo.graphic_requirements" id="graphic_requirements" class="form-control graphic_requirements checkbox" :disabled="(role == 'viewer')?true:false">
                                <label v-if="update_allowed['assets']" class="box-checking pl-0 cursor-pointer" for="graphic_requirements">
                                    <checkbox_checked class="svg-24 mr-2" v-if="orderinfo.graphic_requirements == 1"></checkbox_checked>
                                    <checkbox_empty class="svg-24 mr-2" v-else></checkbox_empty>
                                    <span>Yes</span>
                                </label>
                                <div v-else>
                                    <checkbox_checked class="svg-24 mr-2" v-if="orderinfo.graphic_requirements == 1"></checkbox_checked>
                                    <checkbox_empty class="svg-24 mr-2" v-else></checkbox_empty>
                                    <span>Yes</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="graphics-required-area">
                        <div class="" v-html="email_template_example_text" v-if="email_template_example_text && email_template_example_text != '<p></p>'"></div>

                        <h3 class="order-header mt-5">Email Invitation Preview</h3>
                        <p class="inactive-grey mt-2 font-italic">To change the content of your email, you must revise the appropriate section of your Event Details in the previous tab. Please contact <a href="mailto:fyc@televisionacademy.com" target="_blank" rel="noreferrer">FYC@TelevisionAcademy.com</a> if you need to make edits.</p>

                        <div class="form-calendar form-group-beside row" :class="errorObject['assets'] && errorObject['assets']['emails.0.send_at'] ? 'required' : ''">
                            <label for="send_at" class="col-2 col-md-6">
                                <div>Send Date: <em class="yellow no-style">*</em></div>
                                <div class="description">
                                    Event invitations should be sent 7 to 21 business days prior to your event date.
                                    Email send dates are on a first-come, first-served basis.
                                    Dates that are greyed out have reached the maximum email sends allotted for that day.
                                    Emails are sent Monday - Friday.
                                    Dates are subject to change by Television Academy if assets are late.
                                </div>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper input-wrapper-date position-relative">
                                <calendar-icon data-toggle class="svg-24 svg-accent d-inline-block"></calendar-icon>
                                <flat-pickr data-clear @on-change="eventEmailDateConfigCallback" id="send_at" v-model="orderinfo.emails[0].send_at" :config="dateConfigEmails"
                                            placeholder="Select date" v-if="(orderinfo.event_date && (update_allowed['assets'] && orderinfo.asset_status == 'in_progress')) || role == 'admin'"></flat-pickr>
                                <span class="readonly-date red font-italic" v-else-if="orderinfo.event_date == null">Select an event date before email send date</span>
                                <span class="readonly-date" v-text="orderinfo.emails[0].send_at" v-else></span>
                                <div class="input-group-append position-absolute" v-if="role == 'admin' && orderinfo.emails[0].send_at && orderinfo.emails[0].send_at != ''">
                                    <a href="#" @click.prevent="orderinfo.emails[0].send_at = ''">
                                        <close-icon class="svg-accent"></close-icon>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="form-group-beside row">
                            <label for="subject_line" class="col-2 col-md-6">Subject Line: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <span>
                                    <template v-if="orderinfo.type == 'Virtual'">FYC Virtual Event:</template>
                                    <template v-else>FYC Event: </template>
                                    {{ (orderinfo.event_title != null ? orderinfo.event_title : '') }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group-beside row radio-wrapper" :class="errorObject['assets'] && errorObject['assets']['emails.0.background_color'] ? 'required' : ''">
                            <div class="col-12 col-md-6 radio-button-wrapper-forspacing mb-0 pb-0">
                                Invitation Style? <em class="yellow no-style">*</em>
                            </div>
                            <div class="col-12 col-md-6 mt-2 mb-1 px-0" :class="update_allowed['assets'] ? 'pl-4' : ''">
                                <div class="d-flex pl-3">
                                    <div class="w-50 custom-control custom-radio d-flex align-items-center pl-0 pr-4">
                                        <input v-if="update_allowed['assets']" @change="updateForm('assets','emails.0.background_color',false)" type="radio" v-model="orderinfo.emails[0].background_color" value="0" class="custom-control-input" id="orderinfo_emails_background_color_light" name="orderinfo.background_color">
                                        <div v-else>
                                            <circle-filled-icon v-if="orderinfo.emails[0].background_color == '0'"></circle-filled-icon>
                                            <circle_icon v-else></circle_icon>
                                        </div>
                                        <label class="w-100 custom-control-label no-border pl-1" :class="update_allowed['assets'] ? '' : 'no-click'" for="orderinfo_emails_background_color_light">Light Style</label>
                                    </div>

                                    <div class="w-50 custom-control custom-radio d-flex align-items-center pl-0">
                                        <input v-if="update_allowed['assets']" @change="updateForm('assets','emails.0.background_color',false)" type="radio" v-model="orderinfo.emails[0].background_color" value="1" class="custom-control-input" id="orderinfo_emails_background_color_dark" name="orderinfo.background_color">
                                        <div v-else>
                                            <circle-filled-icon v-if="orderinfo.emails[0].background_color == '1'"></circle-filled-icon>
                                            <circle_icon v-else></circle_icon>
                                        </div>
                                        <label class="w-100 custom-control-label no-border pl-1" :class="update_allowed['assets'] ? '' : 'no-click'" for="orderinfo_emails_background_color_dark">Dark Style</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group-beside row radio-wrapper" :class="errorObject['assets'] && errorObject['assets']['emails.0.invite_wording'] ? 'required' : ''">
                            <div class="col-12 col-md-6 radio-button-wrapper-forspacing mb-0 pb-0">
                                Subject/Verb agreement? <em class="yellow no-style">*</em>
                            </div>
                            <div class="col-12 col-md-6 mt-2 mb-1 px-0" :class="update_allowed['assets'] ? 'pl-4' : ''">
                                <div class="d-flex pl-3">
                                    <div class="w-50 custom-control custom-radio d-flex align-items-center pl-0 pr-4">
                                        <input v-if="update_allowed['assets']" @change="updateForm('assets','emails.0.invite_wording',false)" type="radio" v-model="orderinfo.emails[0].invite_wording" value="invite" class="custom-control-input" id="orderinfo_emails_invite_wording_light" name="orderinfo.invite_wording">
                                        <div v-else>
                                            <circle-filled-icon v-if="orderinfo.emails[0].invite_wording == 'invite'"></circle-filled-icon>
                                            <circle_icon v-else></circle_icon>
                                        </div>
                                        <label class="w-100 custom-control-label no-border pl-1" :class="update_allowed['assets'] ? '' : 'no-click'" for="orderinfo_emails_invite_wording_light">Invite</label>
                                    </div>

                                    <div class="w-50 custom-control custom-radio d-flex align-items-center pl-0">
                                        <input v-if="update_allowed['assets']" @change="updateForm('assets','emails.0.invite_wording',false)" type="radio" v-model="orderinfo.emails[0].invite_wording" value="invites" class="custom-control-input" id="orderinfo_emails_invite_wording_dark" name="orderinfo.invite_wording">
                                        <div v-else>
                                            <circle-filled-icon v-if="orderinfo.emails[0].invite_wording == 'invites'"></circle-filled-icon>
                                            <circle_icon v-else></circle_icon>
                                        </div>
                                        <label class="w-100 custom-control-label no-border pl-1" :class="update_allowed['assets'] ? '' : 'no-click'" for="orderinfo_emails_invite_wording_dark">Invites</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group-beside row" :class="errorObject['assets'] && errorObject['assets']['emails.0.display_option'] && errorObject['assets']['emails.0.display_option'] ? 'required' : ''">
                            <label for="emails_0.display_option" class="col-2 col-md-6">Display Option: <em class="yellow no-style">*</em></label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <v-select v-if="update_allowed['assets']" id="emails_0.display_option" placeholder="Select option" v-model="orderinfo.emails[0].display_option" :options="displayOptions" @input="updateForm('assets','orderinfo.emails[0].display_option',false)"></v-select>
                                <span class="readonly pl-10" v-text="orderinfo.emails[0].display_option" v-if="!update_allowed['assets']"></span>
                            </div>
                        </div>

                        <div v-if="role === 'admin'">
                            <label>Subject (Admin only)</label>
                            <input type="text" v-model="orderinfo.emails[0].subject">
                        </div>

                        <div class="asset-preview-block full-email-wrapper-preview-block row no-gutters mt-5">
                            <div class="col-12 d-flex align-items-start">
                                <div class="background-wrapper mr-4" :class="preview_image['email_invitation_image'] == '' ? 'no-image-uploaded' : ''">
                                    <div class="email-wrapper" :class="orderinfo.emails[0].background_color == 1 ? 'dark-style' : 'light-style'">
                                        <div class="email-header text-center">
                                            <h2 class="text-uppercase">For your consideration Event</h2>
                                        </div>
                                        <div class="text-center subtitle">
                                            <p class="mb-0 text-uppercase">
                                                {{ this.orderinfo.emails[0].subject }}
                                            </p>
                                        </div>
                                        <div class="file-upload-section" :class="orderinfo.cohosted == 1 ? 'pt-0' : 'pt-10'" @click="$refs.email_preview_img.uploadFileClick()">
                                            <div v-if="updatingForm && email_preview_saving" class="show-upload-block form-group pt-0 closed-caption-upload-wrapper file-upload-vue-wrapper mb-0 flex-column">
                                                <div class="spinner large"></div>
                                            </div>
                                            <img :src="preview_image['email_invitation_image']" v-else-if="preview_image['email_invitation_image'] != ''">
                                            <div v-else-if="preview_image['email_invitation_image'] == ''" class="show-upload-block form-group pt-0 closed-caption-upload-wrapper file-upload-vue-wrapper mb-0 flex-column">
                                                <button><upload class="svg-24"></upload> Upload Image</button>
                                                <div class="image-description">
                                                    <p>Must be 650 px wide. Max height 800 pixels</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="event-time mt-5 text-center font-weight-bold f-16 uppercase" :class="orderinfo.emails[0].background_color == 1 ? 'text-white': ''">{{ event_date_display }}</div>
                                        <div class="event-time text-center font-weight-bold f-16" :class="orderinfo.emails[0].background_color == 1 ? 'text-white': ''">{{ orderinfo.event_title }}</div>
                                        <div class="description wysiwyg-content pt-2" :class="orderinfo.emails[0].background_color == 1 ? 'text-white': ''" v-html="orderinfo.invite_description"></div>

                                        <div class="email-footer text-center">
                                            <div>
                                                <div class="section-sub" v-if="orderinfo.type != 'Virtual' && orderinfo.wolf_theatre == 0 && (orderinfo.venue_name || (orderinfo.venue_address && orderinfo.venue_address.street_1 != null))">
                                                    <div><p class="mb-0 font-weight-bold f-16">{{ orderinfo.venue_name }}</p></div>
                                                    <div v-if="orderinfo.venue_address && orderinfo.venue_address.street_1 != null">
                                                        <p>{{ orderinfo.venue_address.street_1 }} <br/>
                                                            <template v-if="orderinfo.venue_address.street_2 != null">{{ orderinfo.venue_address.street_2 }} <br/></template>
                                                            {{ orderinfo.venue_address.city }}<template v-if="orderinfo.venue_address.state != null">, {{ orderinfo.venue_address.state }} {{ orderinfo.venue_address.postal_code }}</template>
                                                            <a :href="orderinfo.venue_address.google_maps_link" class="d-block no-click no-hover" @click.prevent="" target="_blank">Directions</a>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="section-sub" v-else-if="orderinfo.type != 'Virtual' && orderinfo.wolf_theatre == 1">
                                                    <div><p class="mb-0 font-weight-bold f-16">Saban Media Center</p></div>
                                                    <div>
                                                        <p>5210 Lankershim Blvd, North Hollywood,<br/>
                                                            CA 91601, United States
                                                            <a href="https://maps.app.goo.gl/o9jW6biv31Dm7AWt5" class="d-block no-click no-hover" @click.prevent="" target="_blank">Directions</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                            <div class="footer-section">
                                                <a href="#" class="btn btn-primary uppercase btn-wide-small f-16 btn-no-hover no-click" @click.prevent="" v-if="orderinfo.type == 'Virtual'">Click to RSVP</a>
                                                <a href="#" class="btn btn-primary uppercase btn-wide-small f-16 btn-no-hover no-click" @click.prevent="" v-else>register for the RSVP Lottery</a>
                                                <div class="email-footer-questions">
                                                    <p class="mb-0" v-if="orderinfo.type != 'In-Person' && orderinfo.platform_used == 'Own Platform'">Event link and details will be sent to registered attendees prior to the event.</p>
                                                    <p class="mb-0" v-if="orderinfo.type != 'In-Person' && orderinfo.platform_used == 'TVA Viewing Platform'">This event will be presented on the Television Academy's <a href="https://www.emmys.com/viewing/fyc-events" target="_blank" class="no-hover no-click" @click.prevent="">Viewing Platform</a>.</p>
                                                    <p class="mb-0" v-if="orderinfo.type != 'Virtual'">Confirmations will be sent within 48 hours.<br> You can always check the status of your RSVP <a href="https://emmys.com/members/fyc/events" class="no-click no-hover" @click.prevent="">here</a>. </p>
                                                    <p class="mb-0 pt-4">Questions? Visit the <a href="https://televisionacademy.com/faq/fyc" target="_blank" class="no-hover no-click" @click.prevent="">FYC FAQ</a> for answers.</p>
                                                </div>
                                                <div class="email-footer-conditional-terms pb-2">
                                                    <p v-if="orderinfo.type != 'Virtual'">
                                                        Entry is first come, first served. Admittance is not guaranteed.
                                                        Only Television Academy Active/Voting Members will be admitted.
                                                        Members must bring photo identification for check in.
                                                    </p>
                                                    <p v-if="orderinfo.type != 'Virtual'">
                                                        The Television Academy is committed to making member events accessible to everyone. For information about accessibility at FYC venues, <a href="" class="no-hover no-click" @click.prevent="">click here</a>.
                                                    </p>
                                                    <p v-if="orderinfo.type != 'Virtual'">The FYC event host reserves the right to invite guild members and other guests.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="footer-bar text-uppercase">
                                            <p>
                                                This event is presented and hosted by {{ ((orderinfo.network_platform != null) ? (orderinfo.network_platform == 'Other' ? orderinfo.network_platform_other : orderinfo.network_platform) : '[NETWORK]') }},<br> not by the Television Academy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="right-side-spacing" :class="errorObject['assets'] && errorObject['assets']['email_invitation_image'] ? 'text-only-red required' : ''">
                                    <a v-if="orderinfo.email_invitation_image != ''" :href="'/event/' + event.order_id + '/pdf/email'" target="_blank" class="btn btn-primary"><download class="svg-black"></download> Download PDF</a>
                                    <div class="delete-button-top-padding">
                                        <button v-if="orderinfo.email_invitation_image != '' && update_allowed['assets']" @click.prevent="deleteImageFieldName='email_invitation_image'; deleteImageModal = true" class="btn btn-delete"><trash-icon></trash-icon> Delete Image</button>
                                    </div>
                                    <div class="approve-button-placement">
                                        <button v-if="update_allowed['assets']" :disabled="orderinfo.email_invitation_image ? false : true" class="btn btn-primary mr-4" @click.prevent="orderinfo.emails[0].email_approved = !orderinfo.emails[0].email_approved; updateForm('','',false);">
                                            <checkmark v-if="orderinfo.emails[0].email_approved == 1 && orderinfo.email_invitation_image"></checkmark>
                                            <checkbox_empty v-else></checkbox_empty>
                                            Approve Email Invitation</button>
                                        <span class="d-inline-block cursor-initial btn btn-primary btn-no-hover no-click mr-4" v-else>
                                            <checkmark v-if="orderinfo.emails[0].email_approved == 1"></checkmark>
                                            <checkbox_empty v-else></checkbox_empty>
                                            Approve Email Invitation
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <fyc-cropper
                            ref="email_preview_img"
                            v-if="update_allowed['assets']"
                            indexval="11"
                            :initval="orderinfo.email_invitation_image"
                            machine_name="email_invitation_image"
                            min-crop-width="650"
                            min-crop-height="300"
                            max-crop-height="800"
                            :aspectRatio="false"
                            :hasAspectRatio="false"
                            @deleteImage="deleteImage('email_invitation_image')"
                            @croppedImage="saveForValidation"
                            :file-size-restriction="true"
                            :hide_actionable_area="true"
                            :updating-form="updatingForm"
                            :role="role"
                        ></fyc-cropper>

                        <hr class="py-4">

                        <h3 class="order-header mb-0 mt-5">Event Page Assets</h3>
                        <div class="asset-preview-block eventpage-wrapper-preview-block row mt-4 no-gutters">
                            <div class="col-12">
                                <h4 class="order-header mb-4">
                                    Event Page Preview (Mobile Version)
                                </h4>
                                <p class="inactive-grey mt-2 font-italic">To change the content of your event page, you must revise the appropriate section of your Event Details in the previous tab. Please contact <a href="mailto:fyc@televisionacademy.com" target="_blank" rel="noreferrer">FYC@TelevisionAcademy.com</a> if you need to make edits.</p>
                            </div>
                            <div class="col-12 d-flex align-items-start">
                                <div class="background-wrapper mr-4" :class="preview_image['membership_header_image'] == '' ? 'no-image-uploaded' : ''">
                                    <div class="event-page-wrapper dark-style">
                                        <div class="file-upload-section pt-10" @click="$refs.membership_preview_img.uploadFileClick()">
                                            <div v-if="updatingForm && mobile_preview_saving" class="show-upload-block form-group pt-0 closed-caption-upload-wrapper file-upload-vue-wrapper mb-0 flex-column">
                                                <div class="spinner large"></div>
                                            </div>
                                            <img :src="preview_image['membership_header_image']" v-else-if="preview_image['membership_header_image'] != ''">
                                            <div v-else-if="preview_image['membership_header_image'] == ''" class="show-upload-block form-group pt-0 closed-caption-upload-wrapper file-upload-vue-wrapper mb-0 flex-column">
                                                <button><upload class="svg-24"></upload> Upload Image</button>
                                                <div class="image-description">
                                                    <p>Must be 1440 X 640 pixels</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="main-content-area">
                                            <h2 class="mb-2 f-100">{{ orderinfo.event_title }}</h2>
                                            <div class="d-flex justify-content-between align-top">
                                                <div>
                                                    <div class="field-group">
                                                        <label>Event Type</label>
                                                        <div v-if="orderinfo.include_associate_members == 0">{{ 'FYC - ' + 'Open to All Active Members' }}</div>
                                                        <div v-else>{{ 'FYC - ' + 'Open to All Members' }}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-1 mb-4 w-100 d-flex justify-content-between">
                                                <div class="pe-2 pe-lg-5 field-group">
                                                    <label>Date</label>
                                                    <div>{{ event_date_display }}</div>
                                                </div>
                                                <div class="mx-1 mx-sm-3 mx-lg-4 field-group">
                                                    <label>Start Time</label>
                                                    <div class="text-uppercase">{{ orderinfo.time_slot == 'Open' ? orderinfo.time_slot_other : orderinfo.time_slot }}</div>
                                                </div>
                                            </div>

                                            <event-webpage-section-rsvp-button title="RSVP" :livestream="orderinfo.livestream" :type="orderinfo.type"></event-webpage-section-rsvp-button>

                                            <div class="event-description-wrapper">
                                                <event-webpage-section title="About The Event" :content="orderinfo.invite_description" :cohosted="orderinfo.cohosted" :cohosted_with="orderinfo.cohosted_with" :type="orderinfo.type" :platform_used="orderinfo.platform_used"></event-webpage-section>

                                                <event-webpage-section-venue title="Venue Details" :orderinfo="orderinfo"></event-webpage-section-venue>

                                                <event-webpage-section-additional title="Additional Event Information" :orderinfo="orderinfo" v-if="orderinfo.type != 'Virtual' && orderinfo.wolf_theatre != 1"></event-webpage-section-additional>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                                <div class="right-side-spacing" :class="errorObject['assets'] && errorObject['assets']['membership_header_image'] ? 'text-only-red required' : ''">
                                    <a v-if="orderinfo.membership_header_image != ''" :href="'/event/' + event.order_id + '/pdf/webpage'" target="_blank" class="btn btn-primary"><download class="svg-black"></download> Download PDF</a>
                                    <div class="delete-button-top-padding">
                                        <button class="mt-4 btn btn-delete" v-if="orderinfo.membership_header_image != '' && update_allowed['assets']" @click.prevent="deleteImageFieldName='membership_header_image'; deleteImageModal = true"><trash-icon></trash-icon> Delete Image</button>
                                    </div>

                                    <div class="approve-button-placement">
                                        <button v-if="update_allowed['assets']" :disabled="orderinfo.membership_header_image ? false : true" class="btn btn-primary" @click.prevent="orderinfo.webpage_approved = !orderinfo.webpage_approved; updateForm('','',false);">
                                            <checkmark v-if="orderinfo.webpage_approved == 1 && orderinfo.membership_header_image"></checkmark>
                                            <checkbox_empty v-else></checkbox_empty>
                                            Approve Event Page</button>
                                        <span class="d-inline-block cursor-initial btn btn-primary btn-no-hover no-click mr-4" v-else>
                                            <checkmark v-if="orderinfo.webpage_approved == 1"></checkmark>
                                            <checkbox_empty v-else></checkbox_empty>
                                                Approve Event Page
                                         </span>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div>
                            <h4 class="order-header mb-2 mt-4">
                                Event Page Preview (Desktop Version)
                            </h4>
                            <p class="inactive-grey mt-0 font-italic mb-0">Note: The above asset will be also used on the desktop version of the event page.</p>
                            <a @click.prevent="webpageDesktopVersion()" target="_blank" :class="previewDesktopButtonAvailable ? 'cursor-pointer' : 'disabled-no-highlight btn-disabled cursor-initial'" class="">Preview Desktop Version Here</a>
                        </div>

                        <fyc-cropper
                            ref="membership_preview_img"
                            v-if="update_allowed['assets']"
                            indexval="13"
                            :initval="orderinfo.membership_header_image"
                            machine_name="membership_header_image"
                            min-crop-width="1440"
                            min-crop-height="640"
                            @deleteImage="deleteImage('membership_header_image')"
                            @croppedImage="saveForValidation"
                            :file-size-restriction="true"
                            :hide_actionable_area="true"
                            :updating-form="updatingForm"
                            :role="role"
                        ></fyc-cropper>

                        <hr class="py-4">

                        <h3 class="order-header mb-3 mt-5 pt-0">Additional Key Art Sizes</h3>
                        <div class="form-group-beside image_with_preview row mt-2" :class="errorObject['assets'] && errorObject['assets']['email_rsvp_banner_image'] ? 'required' : ''">
                            <label for="cable_network_logo_preview" class="col-2 col-md-6">
                                <div>Small Email Image:</div>
                                <div class="description">Width 570px by 250px.</div>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper image-input-wrapper">
                                <fyc-cropper
                                    v-if="update_allowed['assets']"
                                    indexval="10"
                                    :initval="orderinfo.email_rsvp_banner_image"
                                    machine_name="email_rsvp_banner_image"
                                    min-crop-width="570"
                                    min-crop-height="250"
                                    @deleteImage="deleteImage('email_rsvp_banner_image')"
                                    @croppedImage="saveForValidation"
                                    :file-size-restriction="true"
                                    :updating-form="updatingForm"
                                    :role="role"
                                ></fyc-cropper>
                                <div class="small-img" v-else>
                                    <img :src="orderinfo.email_rsvp_banner_image">
                                </div>
                            </div>
                        </div>

                        <div class="form-group-beside image_with_preview row mt-2" :class="errorObject['assets'] && errorObject['assets']['membership_promo_image'] ? 'required' : ''">
                            <label for="cable_network_logo_preview" class="col-2 col-md-6">
                                <div>Members Site Promo Image:</div>
                                <div class="description">Must be 900 X 600 pixels.</div>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper image-input-wrapper">
                                <fyc-cropper
                                    v-if="update_allowed['assets']"
                                    indexval="12"
                                    :initval="orderinfo.membership_promo_image"
                                    machine_name="membership_promo_image"
                                    min-crop-width="900"
                                    min-crop-height="600"
                                    @deleteImage="deleteImage('membership_promo_image')"
                                    @croppedImage="saveForValidation"
                                    :file-size-restriction="true"
                                    :updating-form="updatingForm"
                                    :role="role"
                                ></fyc-cropper>
                                <div class="small-img" v-else>
                                    <img :src="orderinfo.membership_promo_image">
                                </div>
                            </div>
                        </div>

                        <div class="form-group-beside image_with_preview row" :class="errorObject['assets'] && errorObject['assets']['viewing_platform_image'] ? 'required' : ''">
                            <label for="cable_network_logo_preview" class="col-2 col-md-6">
                                <div>Viewing Platform Banner:</div>
                                <div class="description">Must be 1920 X 1080 pixels.</div>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper image-input-wrapper">
                                <fyc-cropper
                                    v-if="update_allowed['assets']"
                                    indexval="14"
                                    :initval="orderinfo.viewing_platform_image"
                                    machine_name="viewing_platform_image"
                                    min-crop-width="1920"
                                    min-crop-height="1080"
                                    @deleteImage="deleteImage('viewing_platform_image')"
                                    @croppedImage="saveForValidation"
                                    :updating-form="updatingForm"
                                    :role="role"
                                ></fyc-cropper>
                                <div class="small-img" v-else>
                                    <img :src="orderinfo.viewing_platform_image">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5">
                        <div class="d-flex align-items-center justify-content-end"  v-if="update_allowed['assets'] && role != 'admin'">
                            <div class="mr-2">
                                <p class="mb-0" v-if="!orderinfo.webpage_approved && !orderinfo.emails[0].email_approved">You must approve your Email Invitation and Event Page Preview before continuing.</p>
                                <p class="mb-0" v-else-if="!orderinfo.webpage_approved">You must approve your Event Page Preview before continuing.</p>
                                <p class="mb-0" v-else-if="!orderinfo.emails[0].email_approved">You must approve your Email Invitation before continuing.</p>
                                <p class="mb-0" v-else-if="(allowedStatusForEditing.includes(orderinfo.order_status))">You must submit the Event Details section before submitting the event assets.</p>
                                <p class="mb-0" v-else-if="orderinfo.graphic_requirements != 1">You must confirm that you've read the graphic requirements before continuing</p>
                            </div>
                            <button @click.prevent="SaveApplicationStep(2)"
                                    :disabled="(! allowedStatusForEditing.includes(orderinfo.order_status)) && orderinfo.graphic_requirements == 1 && (orderinfo.webpage_approved && orderinfo.emails[0].email_approved) ? false : true"
                                    href="#" class="btn btn-primary btn-step-next">
                                <div>
                                    <span class="submit-text">Approve Asset Details</span>
                                    <span>& Submit For Review</span>
                                </div>
                                <right-small></right-small>
                            </button>
                        </div>
                        <div class="d-flex align-items-center justify-content-end" v-if="role === 'admin'">
                            <a @click.prevent="updateForm('','',true)" type="submit" name="action" value="next" class="btn btn-primary btn-step-next" id="NextButton">
                                <save-icon></save-icon>
                                <div>
                                    <span class="submit-text">Save Order</span>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item accordion-contact-details-wrapper mb-3" id="order-step-2">
            <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between">
                <a @click.prevent="applicationStepSelector(2)" class="w-100 header-button position-relative d-flex justify-content-between accordion-button pl-30 pr-30 py-3 py-lg-2" :class="dynamicClass(2)">
                    <div class="position-absolute app-status-icons">
                        <Attention svg-class="svg-24 red-svg" v-if="iconArray.post_event && iconArray.post_event == 'incomplete'"></Attention>
                        <checkmark svg-class="svg-24 svg-green" v-if="iconArray.post_event && iconArray.post_event == 'complete'"></checkmark>
                    </div>
                    <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header">
                        <h3 class="mb-0">Post Event</h3>
                        <up-chevron-icon class="svg-accent" :class="(orderinfo.application_step == 2) ? 'rotate-0' : 'rotate-180'"></up-chevron-icon>
                    </div>
                </a>
            </div>
            <div id="accordion-post-event" class="pt-3 pb-3 border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="orderinfo.application_step == 2 ? 'show' : ''">
                <div class="mt-3 fields-wrapper-row pl-30">
                    <h3 class="order-header mb-3">Post Event VOD</h3>
                    <div class="row form-group-beside radio-wrapper" :class="errorObject['post_event'] && errorObject['post_event']['event_recorded'] ? 'required' : ''">
                        <div class="col-12 col-md-6 radio-button-wrapper-forspacing">Was this event recorded? <em class="yellow no-style">*</em></div>
                        <div class="col-6 col-md-5 d-flex align-items-center justify-content-between mt-2 mb-1">
                            <div class="custom-control custom-radio mr-5">
                                <input @change="updateForm('post_event','event_recorded',false)" type="radio" v-model="orderinfo.event_recorded" value="1" class="custom-control-input" id="orderinfo.event_recorded_yes" name="orderinfo.event_recorded">
                                <label class="custom-control-label no-border pl-1" for="orderinfo.event_recorded_yes">Yes</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input @change="updateForm('post_event','event_recorded',false)" type="radio" v-model="orderinfo.event_recorded" value="0" class="custom-control-input" id="orderinfo.event_recorded_no" name="orderinfo.event_recorded">
                                <label class="custom-control-label no-border pl-1" for="orderinfo.event_recorded_no">No</label>
                            </div>
                        </div>
                    </div>

                    <div class="post-event-recorded-wrapper" v-if="orderinfo.event_recorded && orderinfo.event_recorded == 1">
                        <div class="form-group-beside row" :class="errorObject['post_event'] && errorObject['post_event']['post_event_embed_code'] ? 'required' : ''">
                            <label for="post_event_embed_code" class="col-2 col-md-6">
                                Embed Code (if applicable):
                                <div class="description">eg: YouTube, Livestream, Vimeo, custom player, etc.</div>
                            </label>
                            <div class="col-10 col-md-6 input-wrapper">
                                <input @change="updateForm('post_event','post_event_embed_code',false)" type="text" v-model="orderinfo.post_event_embed_code" id="post_event_embed_code" class="form-control post_event_embed_code" :disabled="(role == 'viewer')?true:false">
                            </div>
                        </div>
                    </div>

                <video-component-upload v-if="orderinfo.event_recorded == 1"
                                        ref="videoComponent"
                                        :role="role"
                                        :updateallowed="true"
                                        :initialdata="[]"
                                        :isadmin="role==='admin'"
                                        :videoobject="orderinfo.video_post_event"
                                        :bucketname="bucketname"
                                        :entryid="orderinfo.id"
                                        :nominee_video_status="orderinfo.nominee_video_status"
                                        :submitterid="orderinfo.user_id"
                                        :add_another_video_allowed="true"
                                        :videofilestatuses="videofilestatuses"
                                        machine_name="video_post_event"
                                        type="event"
                                        :required-video-uploads="false"
                ></video-component-upload>

                </div>
            </div>
        </div>

        <div class="col-12 invoice-section">
            <div class="text-right">
                <h2>Subtotal: ${{ feeCalculator() }}</h2>
            </div>
        </div>
        <div class="col-12 invoice-section">
            <div>
                <button class="btn btn-primary" @click.prevent="reportOrderIssue = true">Report Order Issue</button>
            </div>
        </div>

        <div class="form-actions border-top-2-dark mt-5" v-if="role !== 'admin'">
            <div class="pt-5 d-flex justify-content-between align-items-center">
                <a class="btn btn-delete" @click.prevent="deleteModal = true" v-if="orderinfo.order_status != 'approved' && orderinfo.order_status != 'canceled'">Delete</a>
                <a :href="eventsurl" class="save-button btn btn-primary mr-0" id="SaveButton_bottom"><save-icon></save-icon> Save for Later</a>
            </div>
        </div>
    </div>

    <modal v-if="deleteModal" @close="deleteModal = false">
        <h3 slot="header">Delete Submission</h3>
        <div class="content" slot="body">
            <p>Are you sure?</p>
        </div>
        <div class="form-group-submit" slot="footer">
            <button class="pull-left btn-black" @click="deleteModal = false">Close</button>
            <button class="btn btn-primary pull-right" @click.prevent="deleteOrder">Yes, Delete</button>
        </div>
    </modal>

    <modal v-if="signatureNeeded" @close="signatureNeeded = false" modalclass="modal-signature">
        <h2 class="description" slot="header">Sign in the window below using your track pad or mouse</h2>
        <div class="content" slot="body">
            <signature-pad @closeModalClicked="closeModal" @signatureSave="saveSignature"></signature-pad>
        </div>
        <div class="form-group-submit" slot="footer">
        </div>
    </modal>

    <modal v-if="showSavingModal" @close="showSavingModal = false" class="savingmodal">
        <h1 slot="header" v-text="" v-show="false"></h1>
        <div class="ballot-submitting-modal-content content" slot="body">
            <div class="spinner"></div>
        </div>
        <div class="form-group-submit" slot="footer" v-show="false"></div>
    </modal>

    <modal class="limited-width-modal" v-if="showOrderDetailsCompletePopup" @close="showOrderDetailsCompletePopup = false">
        <h3 slot="header">Submit Event Details for Approval</h3>
        <div class="content" slot="body">
            <p>
                Please click below to submit for approval. Upon submission the Television Academy event production team
                will review your event details. You may continue and update your event assets below.
            </p>
        </div>
        <div class="form-group-submit" slot="footer">
            <a class="btn btn-black pull-left" @click="showOrderDetailsCompletePopup = false">Close</a>
            <button class="btn btn-primary pull-right" @click.prevent="submitForm_OrderDetails('details')">Submit Now</button>
        </div>
    </modal>

    <modal class="limited-width-modal" v-if="showOrderAssetsCompletePopup" @close="showOrderAssetsCompletePopup = false">
        <h3 slot="header">Submit Event Assets for Approval</h3>
        <div class="content" slot="body">
            <p>
                Please click below to submit for approval. Upon submission the Television Academy marketing team will
                review your event assets.
            </p>
        </div>
        <div class="form-group-submit" slot="footer">
            <a class="btn btn-black pull-left" @click="showOrderAssetsCompletePopup = false">Close</a>
            <button class="btn btn-primary pull-right" @click.prevent="submitForm_OrderDetails('assets')">Submit Now</button>
        </div>
    </modal>

        <modal v-if="deleteImageModal" @close="deleteImageModal = false">
            <h3 slot="header">Remove Image</h3>
            <div class="content" slot="body">
                <p>Are you sure you want to remove this image?</p>
            </div>
            <div class="form-group-submit d-flex justify-content-between align-items-center" slot="footer">
                <button class="btn btn-primary" @click.prevent="deleteImageModal = false">Close</button>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="spinner xsmall mr-2" v-if="email_preview_saving || mobile_preview_saving"></div>
                    <button class="btn btn-black" @click.prevent="deleteImage(deleteImageFieldName)">Yes, Delete</button>
                </div>
            </div>
        </modal>


        <modal v-if="graphics_acknowledgment_text_modal" @close="graphics_acknowledgment_text_modal = false">
            <h3 slot="header" class="m-auto">FYC Partner Graphic Requirements</h3>
            <div class="content" slot="body">
                <div v-html="graphics_acknowledgment_text"></div>
            </div>
            <div class="form-group-submit d-flex justify-content-center align-items-center" slot="footer">
                <button class="btn btn-primary btn-wide" @click.prevent="graphics_acknowledgment_text_modal = false">Ok</button>
            </div>
        </modal>

        <modal v-if="reportOrderIssue" @close="reportOrderIssue = false">
            <h3 slot="header">Report Order Issue</h3>
            <div class="content" slot="body">
                <div class="select">
                    <select id="submitter_issue_status" v-model="orderinfo.submitter_issue_status" class="plain-select">
                        <option :selected="orderinfo.submitter_issue_status" v-for="(value, key) in submitter_issue_statuses" :value="key" v-text="value"></option>
                    </select>
                    Issue Details:
                    <textarea rows="5" type="text" id="submitter_issue_details" v-model="orderinfo.submitter_issue_details" class="form-control" ></textarea>

                </div>
            </div>
            <div class="form-group-submit d-flex justify-content-between align-items-center" slot="footer">
                <button class="btn btn-primary" @click.prevent="reportOrderIssue = false">Close</button>
                <div class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-black" @click.prevent="submitOrderIssue()">Submit</button>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
// register modal component
import modal from '../Modal.vue';
import EventWebpageSection from "@/components/Events/EventWebpageSection.vue";
import EventWebpageSectionVenue from "@/components/Events/EventWebpageSectionVenue.vue";
import EventWebpageSectionAdditional from "@/components/Events/EventWebpageSectionAdditional.vue";
import EventWebpageSectionRsvpButton from "@/components/Events/EventWebpageSectionRsvpButton.vue";
import FycCropper from '../FycCropper.vue';
import circle_check from "@/Icons/circle_check.vue";
import circle_icon from "@/Icons/circle_icon.vue";
import SaveIcon from "@/Icons/save.vue";
import CloseIcon from "@/Icons/close.vue";
import Close from "@/Icons/member.vue";
import Checkmark from "@/Icons/checkmark.vue";
import RightSmall from "@/Icons/right-small.vue";
import CalendarIcon from "@/Icons/calendar.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";
import UpChevronIcon from "@/Icons/up-chevron.vue";
import Attention from "@/Icons/attention.vue";
import Checkbox_checked from "@/Icons/checkbox_checked.vue";
import Checkbox_empty from "@/Icons/checkbox_empty.vue";
import UpArrowBox from "@/Icons/up-arrow-box.vue";
import WysiwygInput from "@/components/Form/WysiwygInput.vue";
import CircleFilledIcon from "@/Icons/circle_filled.vue";
import Submitted from "@/Icons/submitted.vue";
import Question from "@/Icons/question.vue";
import Download from "@/Icons/download.vue";
import TrashIcon from "@/Icons/trash.vue";
import MinusLarge from "@/Icons/minus-large.vue";
import Down_box from "@/Icons/down_box.vue";

export default {
    components: {
        Down_box,
        MinusLarge,
        TrashIcon,
        Download,
        Question,
        Submitted,
        CircleFilledIcon,
        WysiwygInput,
        UpArrowBox,
        Checkbox_empty,
        Checkbox_checked,
        Attention,
        UpChevronIcon,
        DownChevronIcon,
        RightSmall,
        Checkmark,
        Close,
        CloseIcon,
        SaveIcon,
        modal,
        EventWebpageSection,
        EventWebpageSectionVenue,
        EventWebpageSectionAdditional,
        EventWebpageSectionRsvpButton,
        FycCropper,
        circle_check,
        circle_icon,
        CalendarIcon
    },
    props: {
        actionurl_assets: String,
        actionurl_assets: String,
        actionurl_details: String,
        actionurl_send_at_time: String,
        actionurl_team_assigned: String,
        actionurl: String,
        asset_statuses: Array,
        bucketname: String,
        desktop_preview_url: String,
        email_template_example_text: String,
        event_display_options: Array,
        event: Array,
        events_acknowledgement: String,
        events_booking_end_date: String,
        events_booking_start_date: String,
        eventsurl: String,
        excluded_dates: Array,
        graphics_acknowledgment_text: String,
        invite_description_example: String,
        invoices: Array,
        migrateurl: String,
        report_issue_url: String,
        role: String,
        states: Array,
        submission_statuses: Array,
        submitter_issue_statuses: Array,
        submiturl: String,
        team_assigned_members: Array,
        update_allowed: Array,
        user_change_url: String,
        user_suggestions_url: String,
        videofilestatuses: Array,
        amount: {
            type: Number,
            default: 0
        },
        amountDue: {
            type: Number,
            default: 0
        },
        amountPaid: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            allowedStatusForEditing: ['in_progress'],
            statusForIndicators: ['need_more_info', 'submitted', 'approved', 'order_completed'],
            statusForIndicatorsAssets: ['need_more_info', 'submitted', 'internal_review', 'approved', 'building', 'build_complete', 'published', 'order_completed'],
            orderinfo: this.event,
            signatureNeeded: '',
            deleteModal: false,
            showSavingModal: false,
            updatingForm: false,
            mobile_preview_saving: false,
            email_preview_saving: false,
            reportOrderIssue: false,
            cable_networkOptions: ["Other","A&E","ABC","ABC\/Syndicated","Acorn TV","Adult Swim","ALLBLK","Amazon Freevee","AMC","AMC+","Apple TV+","BBC America","BET+","BET Networks","Bravo","BritBox","BYUtv","CBS","CMT","CNBC","CNN","Comedy Central","Crackle","CW","Discovery+","Discovery Channel","Disney+","Disney XD","E!","ESPN","ESPN+","Facebook Watch","Food Network","FOX","Freeform","FunnyOrDie","Fuse","FX","GSN","Hallmark Channel","Hallmark Movies & Mysteries","HBO | Max","HGTV","HISTORY","Hulu","IFC","IGTV","Investigation Discovery","Lifetime","Logo","MGM+","MSNBC","MTV","Nat Geo WILD","National Geographic","NBC","Netflix","NFL Network","OWN","Oxygen","Paramount+","Paramount Network","PBS","Peacock","Pluto","Prime Video","Reelz","Revry","Roku Channel","Science Channel","Showtime","Smithsonian Channel","Snapchat","Spectrum","STARZ","Sundance Now","SundanceTV","SYFY","TBS","Telemundo","TLC","TNT","Travel Channel","truTV","Tubi","UNIVISION","USA","VH1","VICE TV","WWE Network","YouTube","YouTube Originals"],
            yesNoOptions: ['Yes', 'No'],
            closedCaptioningForVideoOptions: ['Yes', 'No', 'N/A'],
            errorCodes: [401, 403, 419],
            eventTimeOptions: [
                {
                    label: '11:00 AM PT (Weekend Only)', code: '11:00 AM'
                },
                {
                    label: '3:00 PM PT (Weekend Only)', code: '3:00 PM'
                },
                {
                    label: '7:00 PM PT (Weekend Only)', code: '7:00 PM'
                },
                {
                    label: '4:00 PM PT (7:00 PM ET) (East Coast only)', code: '4:00 PM'
                },

            ],
            eventLocationOptions: [
                {
                    label: 'Los Angeles', code: 'Los Angeles'
                },
                {
                    label: 'New York', code: 'New York'
                },
                {
                    label: 'Atlanta', code: 'Atlanta'
                },
                {
                    label: 'DC', code: 'DC'
                },
                {
                    label: 'Other', code: 'Other'
                },
            ],
            dateConfig: {
                wrap: true,
                dateFormat: 'm-d-Y',
                minDate: this.events_booking_start_date,
                maxDate: this.events_booking_end_date,

            },
            dateConfigEmails: {
                wrap: true,
                dateFormat: 'm-d-Y',
                disable: this.excluded_dates,
                minDate: this.event.send_at_start_date,
                maxDate: this.event.send_at_end_date,
            },
            buttons: ['bold', 'underline', 'italic', 'eraser', 'ul', 'ol', 'link'],
            toggleClass: {
                include_associate_members: '',
                standby_line: '',
                allow_plus_one: '',
                other_groups_invited_special_line: '',
                other_groups_invited: '',
                ride_share_vouchers: '',
                cohosted: '',
                cohosted_special_line: '',
                cohosted_hosted_bar: '',
                cohosted_concession: '',
                event_recorded: '',
                ada_listening_devices: '',
                ada_listening_closed_captioning: '',
                ada_listening_adl_interpreter: '',
                ada_wheelchair_lift: '',
            },
            iconArray: {
                'details': {},
                'assets': {},
                'post_event': {}
            },
            errorObject: {
                'details': {},
                'assets': {},
                'post_event': {}
            },
            savingData: false,
            clickedContinue: false,
            inputStepClicked: '',
            event_date_display: '',
            showOrderDetailsCompletePopup: false,
            showOrderAssetsCompletePopup: false,
            eventEmailSubtitle: {
                'In-Person': 'An In-Person Event',
                'Virtual': 'A Virtual FYC Event',
                'Hybrid': 'An In-Person and Virtual Event',
            },
            displayOptions: [],
            preview_image: {
                'email_invitation_image': this.event.email_invitation_image != '' ? this.event.email_invitation_image : '',
                'membership_header_image': this.event.membership_header_image != '' ? this.event.membership_header_image : '',
            },
            destination_encoded: '',
            deleteImageModal: false,
            deleteImageFieldName: '',
            previewDesktopButtonAvailable: false,
            graphics_acknowledgment_text_modal: false,
            fieldsThatChangeSubject: [
                'network_platform',
                'network_platform_other',
                'allow_plus_one',
                'emails.0.invite_wording',
                'orderinfo.emails[0].display_option',
                'cohosted',
                'cohosted_with',
            ],
            fieldsThatChangeGoogleMapsLink: [
                'venue_name',
                'venue_address.street_1',
                'venue_address.street_2',
                'venue_address.city',
                'venue_address.state',
                'venue_address.postal_code',
            ],
            fieldsThatChangeVenueCapacity: [
                'tva_member_seats',
                'holdback_seats',
            ],
            imageFieldNames: [
                'cable_network_logo',
                'program_logo',
                'email_invitation_image',
                'membership_header_image',
                'email_rsvp_banner_image',
                'membership_promo_image',
                'viewing_platform_image',
            ],
            venueCapacityManuallyUpdated: false,
        }
    },
    mounted: function() {
        this.displayOptions = this.event_display_options;
        this.displayOptions.unshift(this.eventEmailSubtitle[this.orderinfo.type]);

        if(this.role == 'admin') {
            this.orderinfo.application_step = -1;
        }

        if(this.orderinfo.billing_address == null) {
            this.orderinfo.billing_address = {
                street_1: null,
                street_2: null,
                city: null,
                state: null,
                postal_code: null,
            };
        }
        if(this.orderinfo.venue_address == null) {
            this.orderinfo.venue_address = {
                street_1: null,
                street_2: null,
                city: null,
                state: null,
                postal_code: null,
            };
        }

        // Check if the venue capacity has been manually updated beforehand
        if ((this.orderinfo.venue_capacity ?? 0) != this.calculateVenueCapacity()) {
            this.venueCapacityManuallyUpdated = true;
        }

        if(! this.update_allowed['details']) {
            this.inputReadOnly('#accordion-order-details');
        }

        if(! this.update_allowed['assets']) {
            this.inputReadOnly('#accordion-order-assets');
        }

        // Loop through all items in toggleClass and run the toggleFunctionality method
        for (let key in this.toggleClass) {
            this.toggleFunctionality(key, this.orderinfo[key], 'onload', 'details');
        }

        if (this.orderinfo.membership_header_image) {
            this.previewDesktopButtonAvailable = true;
        }

        this.eventDateFormatting();
        this.iconArrayConfiguration()
    },
    methods: {
        eventDateConfigCallback(selectedDates, dateStr, instance) {
            if(this.role == 'admin') {
                this.eventDateFormatting();
            }
            this.orderinfo.time_slot = '';
            this.updateForm('details','event_date',false)
        },
        eventEmailDateConfigCallback(selectedDates, dateStr, instance) {
            this.updateForm('details','emails[0].send_at',false)
        },
        dynamicClass(input_step) {
            if(this.orderinfo.application_step == -1) {
                return;
            }
            return;
        },
        applicationStepSelector(input) {
            if(input == this.orderinfo.application_step) {
                this.orderinfo.application_step = -1;
            } else {
                this.orderinfo.application_step = input;
                this.$scrollTo('#order-step-'+(input-1), 700);
            }
        },
        SaveApplicationStep(inputStep) {
            this.inputStepClicked = inputStep
            this.clickedContinue = true;
            if(!this.savingData) {
                this.actualSaveApplicationStep(this.inputStepClicked)
            }
        },
        actualSaveApplicationStep() {
            let app = this;
            if(app.inputStepClicked === app.orderinfo.application_step) {
                app.orderinfo.application_step = -1;
                return;
            }
            if(app.updatingForm) {
                return;
            }
            app.updatingForm = true;

            axios.post('/apply/step', {
                'event_id': app.orderinfo.id,
                'application_step': app.inputStepClicked
            }).then(function(response) {
                // app.orderinfo = response.data.eventData;
                app.iconArray = response.data.status_icons;
                app.clickedContinue = false
                app.eventDateFormatting();

                if(response.data.errors && (response.data.errors.assets || response.data.errors.details)) {
                    app.errorObject = response.data.errors;
                }

                // Asset Details and/or Order Details complete, no errors get them to submit.
                if((app.inputStepClicked == 2) && (_.size(app.errorObject.details) == 0) && (_.size(app.errorObject.assets) == 0)) {
                    app.showOrderAssetsCompletePopup = true;
                } else if((app.inputStepClicked == 1) && (_.size(app.errorObject.details) == 0)) {
                    app.showOrderDetailsCompletePopup = true;
                } else {
                    app.$scrollTo('#top-of-form', 700);
                    app.$root.$refs.flash_alert.activateFlashAlert('Fill in all the required fields before proceeding.', 'error');
                    app.showErrorMessage = true;
                }
                app.updatingForm = false;

            }).catch(function (err) {
                if(err.response && app.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }

                let errorMessage = err.response.data.message;
                app.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                app.showErrorMessage = true;
                app.updatingForm = false;
            });
        },
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.orderinfo.cable_network_logo = this.$refs.cropper.getCroppedCanvas().toDataURL();
        },
        timeSlotsBasedOnDates(dateInput) {
            if(dateInput.getDay() === 0 || dateInput.getDay() === 6) {
                    this.eventTimeOptions = [
                    {
                        label: '11:00 AM PT (Weekend Only)', code: '11:00 AM'
                    },
                    {
                        label: '3:00 PM PT (Weekend Only)', code: '3:00 PM'
                    },
                    {
                        label: '7:00 PM PT (Weekend Only)', code: '7:00 PM'
                    },
                    {
                        label: 'Open slot in East Coast market (Weekend only)', code: 'Open'
                    },                ];
            } else {
                this.eventTimeOptions = [
                    {
                        label: '4:00 PM PT (7:00 PM ET) (East Coast only)', code: '4:00 PM'
                    },
                    {
                        label: '5:00 PM PT (Southern California location, OR a virtual-only)', code: '5:00 PM'
                    },
                    {
                        label: '7:00 PM PT', code: '7:00 PM'
                    }
                ];
            }
        },
        calculateVenueCapacity() {
            return (parseInt(this.orderinfo.tva_member_seats) ? parseInt(this.orderinfo.tva_member_seats) : 0)
                + (parseInt(this.orderinfo.holdback_seats) ? parseInt(this.orderinfo.holdback_seats) : 0);
        },
        updateForm: function(field_group, field_name, adminSave) {
            let that = this;

            // Automatically update venue capacity
            if (this.fieldsThatChangeVenueCapacity.includes(field_name) && !this.venueCapacityManuallyUpdated) {
                const calculatedVenueCapacity = this.calculateVenueCapacity();
                if (calculatedVenueCapacity > 0) {
                    this.orderinfo.venue_capacity = calculatedVenueCapacity.toString();
                }
            }

            if((that.role === 'admin') && (adminSave !== true)) {
                return false;
            }

            if(that.updatingForm) {
                return;
            }
            that.updatingForm = true;

            if(this.fieldsThatChangeSubject.includes(field_name)) {
                this.orderinfo.emails[0].email_approved = 0;
                this.setEmailSubject();
            }

            if(this.fieldsThatChangeGoogleMapsLink.includes(field_name)) {
                let venue_location_to_encode = this.orderinfo.venue_name ? this.orderinfo.venue_name + ', ' : '';
                venue_location_to_encode += this.orderinfo.venue_address.street_1 ? this.orderinfo.venue_address.street_1 + ', ' : '';
                venue_location_to_encode += this.orderinfo.venue_address.street_2 ? this.orderinfo.venue_address.street_2 + ', ' : '';
                venue_location_to_encode += this.orderinfo.venue_address.city ? this.orderinfo.venue_address.city + ', ' : '';
                venue_location_to_encode += this.orderinfo.venue_address.state ? this.orderinfo.venue_address.state + ', ' : '';
                venue_location_to_encode += this.orderinfo.venue_address.postal_code ? this.orderinfo.venue_address.postal_code : '';
                this.orderinfo.venue_address.google_maps_link = 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(venue_location_to_encode);
            }

            // Remove required field 'required' indicator if field is filled in
            if(field_group && field_name) {
                that.errorObject[field_group][field_name] = '';
            }

            axios({
                method: 'put',
                url: that.actionurl,
                data: that.orderinfo
            }).then(function(response) {
                let dataBack = response.data;
                if((that.role === 'admin') || (adminSave)) {
                    location.reload();
                }
                if (dataBack.event.event_date) {
                    that.dateConfigEmails.minDate = dataBack.event.send_at_start_date;
                    that.dateConfigEmails.maxDate = dataBack.event.send_at_end_date;
                }
                if (dataBack.event.membership_header_image) {
                    setTimeout(function() {
                        that.previewDesktopButtonAvailable = true;
                    }, 600);  // Need a delay as the image is not available immediately
                }
                if(that.imageFieldNames.includes(field_name)) {
                    that.orderinfo[field_name] = dataBack.event[field_name];
                }

                that.eventDateFormatting();
                that.excluded_dates = dataBack.excluded_dates;
                that.dateConfigEmails.disable = dataBack.excluded_dates;
                that.updatingForm = false;
                that.mobile_preview_saving = false;
                that.email_preview_saving = false;
                that.deleteImageModal = false;
            }).catch(function (err) {

                // if err.response.status is in the errorCodes array, reload the page
                if(err.response && that.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }

                // console.log('test', err.response, '---', err.response.status, that.errorCodes.includes(err.response.status) ? 'blue' : 'red')
                if(err.response && that.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                if (err.response) {
                    let errorMessage = err.response.data.message;
                    that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    that.$emit('showflashalert');
                    that.$scrollTo('#top-of-form', 700);
                }
                that.updatingForm = false;
            });
        },
        setEmailSubject: function() {
            let subject = ((this.orderinfo.network_platform != null) ? (this.orderinfo.network_platform == 'Other' ? this.orderinfo.network_platform_other : this.orderinfo.network_platform) : '[NETWORK]') + ' ';
            subject += this.orderinfo.emails[0].invite_wording ? this.orderinfo.emails[0].invite_wording : '';
            subject += this.orderinfo.allow_plus_one == 1 ? ' you and a guest' : ' you';
            subject += ' to ';
            subject += this.orderinfo.emails[0].display_option ? this.orderinfo.emails[0].display_option : '';
            subject += this.orderinfo.cohosted == 1 ? ' CO-HOSTED WITH ' + this.orderinfo.cohosted_with : '';
            this.orderinfo.emails[0].subject = subject;
            return subject;
        },
        submitForm_OrderDetails: function (step_in_form) {
            let that = this;
            if (that.updatingForm) {
                return;
            }
            that.updatingForm = true;

            window.axios(
                {
                    method: 'put',
                    url: this.submiturl,
                    data: {
                        'form_submitted': step_in_form,
                    },
                }).then(function (response) {
                window.onbeforeunload = function () {
                    window.scrollTo(0, 0);
                }
                window.location.reload();

                // Reloading the page to ensure locked fields are controlled by the backend consistently.
            }).catch(function (err) {
                if (err.response && that.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }

                // handle error
                that.showOrderDetailsCompletePopup = false;
                that.showOrderAssetsCompletePopup = false;
                that.showSavingModal = false;
                if(err.response != undefined) {
                    let errorMessage = err.response.data.message;
                    that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    that.$scrollTo('#top-of-form', 700);
                }
                that.updatingForm = false;
            });
        },
        deleteOrder: function() {
            let that = this;
            if(that.updatingForm) {
                return;
            }
            that.updatingForm = true;

            window.axios(
                {
                    method: 'delete',
                    url: this.actionurl,
                    data: that.orderinfo
                }).then(function(response) {
                let dataBack = response.data;
                window.location.href = dataBack.redirect_to;
            }).catch(function (err) {
                if(err.response && that.errorCodes.includes(err.response.status)) {
                    window.location.reload();
                }
                that.updatingForm = false;
            });
        },
        signatureNeededPopover: function() {
            let that = this;
            if(!that.update_allowed['details']) {
                return false;
            }
            document.body.classList.add("overlay");
            that.signatureNeeded = true;
        },
        closeModal: function() {
            document.body.classList.remove("overlay");
            this.signatureNeeded = false;
        },
        saveSignature: function(signature) {
            this.orderinfo.signature = signature;
            this.signatureNeeded = false;
            this.updateForm('details','signature',false);
        },
        saveForValidation: function(filename, field_name, fileData) {
            this.orderinfo[field_name] = fileData;
            if(field_name == 'membership_header_image') {
                this.orderinfo.webpage_approved = 0;
                this.mobile_preview_saving = true;
            }
            if(field_name == 'email_invitation_image') {
                this.orderinfo.emails[0].email_approved = 0;
                this.email_preview_saving = true;
            }
            if(field_name == 'membership_header_image' || field_name == 'email_invitation_image') {
                this.preview_image[field_name] = fileData;
            }

            let field_group = '';
            if((field_name == 'cable_network_logo') || (field_name == 'program_logo')) {
                field_group = 'details';
            } else {
                field_group = 'assets';
            }
            this.updateForm(field_group, field_name,false);
        },
        fileSaveForValidation: function(filename, field_name, fileData) {
            this.orderinfo[field_name] = filename;
        },
        inputReadOnly: function(accordion_wrapper_id) {
            let wrapper = accordion_wrapper_id + ' input, ' + accordion_wrapper_id + ' textarea';
            let textareas=document.querySelectorAll(wrapper),
                x=textareas.length;
            while(x--)
                textareas[x].readOnly=1;
        },
        deleteImage: function(fieldName) {
            this.orderinfo[fieldName] = '';
            if(fieldName == 'membership_header_image') {
                this.preview_image[fieldName] = '';
                this.mobile_preview_saving = true;
            }
            if(fieldName == 'email_invitation_image') {
                this.preview_image[fieldName] = '';
                this.email_preview_saving = true;
            }
            this.updateForm('','',false);
            if(this.role == 'admin') {
                this.deleteImageModal = false;
            }
        },
        toggleFunctionality: function(fieldName, value, saveData, form_section) {
            this.orderinfo[fieldName] = value;
            if(this.orderinfo[fieldName] == 1) {
                this.toggleClass[fieldName] = 'active-yes' + (this.update_allowed[form_section] ? '' : ' no-hover');
            } else if (this.orderinfo[fieldName] == 0) {
                this.toggleClass[fieldName] = 'active-no' + (this.update_allowed[form_section] ? '' : ' no-hover');
            } else if (this.orderinfo[fieldName] == 'n/a') {
                this.toggleClass[fieldName] = 'active-na' + (this.update_allowed[form_section] ? '' : ' no-hover');
            } else if (this.orderinfo[fieldName] == 'maybe') {
                this.toggleClass[fieldName] = 'active-maybe' + (this.update_allowed[form_section] ? '' : ' no-hover');
            }

            if(saveData != 'onload') {
                this.updateForm(form_section, fieldName, false);
            }
        },
        webpageDesktopVersion: function () {
            if((this.orderinfo.membership_header_image) && (this.previewDesktopButtonAvailable)) {
                window.open(this.desktop_preview_url, "_blank");
            }
        },
        iconArrayConfiguration: function() {
            if((! this.allowedStatusForEditing.includes(this.orderinfo.order_status)) && (this.orderinfo.order_status != 'canceled')) {
                this.iconArray['details'] = 'lock'
            }
            if((! this.allowedStatusForEditing.includes(this.orderinfo.asset_status)) && (this.orderinfo.asset_status != 'canceled')) {
                this.iconArray['assets'] = 'lock'
            }
        },
        feeCalculator: function() {
            let feeAmount = 5000;
            if(this.orderinfo.livestream == 1) {
                feeAmount += 2500;
            }
            return feeAmount;
        },
        redirectPage: function(actionurl) {
            window.location.href = actionurl
        },
        eventDateFormatting: function() {
            // Get timeslots based on date. Format date for readability.
            if(this.orderinfo.event_date) {
                let eventDateTemp = this.orderinfo.event_date.replace(/-/g, '/');
                let eventDate = new Date(eventDateTemp);
                let month = eventDate.getMonth() + 1;
                let day = eventDate.getDate();
                let year = eventDate.getFullYear();
                this.event_date_display = eventDate.toLocaleString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"});
                this.timeSlotsBasedOnDates(eventDate);
            }
        },
        submitOrderIssue: function () {
            window.axios(
                {
                    method: 'post',
                    url: this.report_issue_url,
                    data: {
                        'submitter_issue_status': this.orderinfo.submitter_issue_status,
                        'submitter_issue_details': this.orderinfo.submitter_issue_details,
                    },
            }).then(function (response) {
                that.reportOrderIssue = false;
                //window.location.reload();
            }).catch(function (err) {
                if(err.response != undefined) {
                    let errorMessage = err.response.data.message;
                    that.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    that.$scrollTo('#top-of-form', 700);
                }
                that.reportOrderIssue = false;
            });

        }
    },
}
</script>

<style scoped> .success { color:green; } </style>
