<template>
    <div class="delete-entry-form">
        <!-- should this use btn-delete class instead? -->
        <a class="btn btn-cancel modal-third btn-icon" id="CancelButton" @click.prevent="deleteModal = true"><trash-icon></trash-icon></a>

        <modal v-if="deleteModal" @close="deleteModal = false">
            <h3 slot="header">Delete Submission</h3>
            <div class="content" slot="body">
                <p>Are you sure you want to delete this order?</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn-black" @click="deleteModal = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="deleteOrder">Yes, Delete</button>
            </div>
        </modal>
    </div>
</template>

<script>
    // register modal component
    import modal from '../components/Modal.vue';
    import TrashIcon from "@/Icons/trash.vue";

    export default {
        components: {TrashIcon, modal},
        props: ['deleteurl'],
        data() {
            return {
                deleteModal: false
            }
        },
        methods: {
            deleteOrder: function() {
                let that = this;
                window.axios(
                    {
                        method: 'delete',
                        url: this.deleteurl
                    }).then(function(response) {
                    let dataBack = response.data;
                    window.location.href = dataBack.redirect_to;
                });
            }
        }
    }

</script>
