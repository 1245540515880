<template>
    <div class="col-12">
        Some Events
    </div>
    </template>

    <script>

        export default {
            components: {},
            props: {
                events_list: Array,
            },
            created() {

            },
            data() {
                return {

                }
            },
            methods: {


            }
        }
    </script>
