/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';

import Vue from 'vue';
import.meta.glob([
    '../images/**',
]);

import DataTable from 'datatables.net-dt';

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)

// Draggable
import draggable from 'vuedraggable'
Vue.component('draggable', draggable);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
import "vue-select/dist/vue-select.css";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
Vue.component('flatPickr', flatPickr);

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

// import Datepicker from 'vuejs-datepicker';
// Vue.component('Datepicker', Datepicker);

// Vee Validate
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import LaravelVuePagination from 'laravel-vue-pagination';
import FlashAlert from "./components/FlashAlert.vue";
import VideoUploadTestComponent from "./components/VideoUploadTestComponent.vue";
import ErrorMessage from "./components/ErrorMessage.vue";
import OrderVpForm from "./components/OrderVpForm.vue";
import OrderEmmyNetworkForm from "./components/OrderEmmyNetworkForm.vue";
import OrderDelete from "./components/OrderDelete.vue";
import SignaturePad from "./components/SignaturePad.vue";
import FycCropper from "./components/FycCropper.vue";
import Instructions from "./components/Instructions.vue";
import CreateOrder from "./components/CreateOrder.vue";
import CheckoutTable from "./components/CheckoutTable.vue";
import InvoicePayment from "./components/InvoicePayment.vue";
import VerifyMicrodeposits from "./components/Invoices/VerifyMicrodeposits.vue";
import AdminOrderStatusUpdate from "./components/AdminOrderStatusUpdate.vue";
import FinancialsTable from "./components/FinancialsTable.vue";
import InvoiceForm from "./components/Finance/InvoiceForm.vue";
import OrdersTable from "./components/OrdersTable.vue";
import AdminSearch from "./components/OrdersAdminSearch.vue";
import AdminEventsSearch from "./components/Events/AdminEventsSearch.vue";
import TeamAssignmentUpdate from "./components/Events/TeamAssignmentUpdate.vue";
import QuestionPopup from "./components/QuestionPopup.vue";
import AdminChangeUser from "./components/AdminChangeUser.vue";
import AdminInternalNotes from "./components/AdminInternalNotes.vue";
import VideoUploadComponent from "./components/VideoUploadComponent.vue";
import TabularReport from "./components/Reports/TabularReport.vue";
import EventsTable from "./components/Events/EventsTable.vue";
import FileUpload from "./components/FileUpload.vue";
import FileUploadInTemplates from "./components/FileUploadInTemplates.vue";
import WysiwygInput from "@/components/Form/WysiwygInput.vue";
import UsersTable from "@/components/UsersTable.vue";

// Event Functionality
import CreateEvent from "./components/Events/CreateEvent.vue";
import EventOrderForm from "./components/Events/EventOrderForm.vue";
import AdminStatuses from "@/components/AdminStatuses.vue";
import WebpageDesktopPreview from "@/components/Events/WebpageDesktopPreview.vue";
import MonthAccordion from "@/components/MonthAccordion.vue";
import WysiwygReadonlyToggle from "@/components/WysiwygReadonlyToggle.vue";
import PhoneNumberInput from "./components/Form/PhoneNumberInput.vue";
import PasswordInput from "./components/Form/PasswordInput.vue";
import EventsListView from './components/Events/EventsListView.vue';
import EventsCalendar from './components/Events/EventsCalendar.vue';

// No message specified.
extend('email', email);

// Override the default message.
extend('required', {
    ...required,
    message: 'This field is required'
});
// Override the default message.
extend('email', {
    ...email,
    message: 'Please use a valid email address'
});
extend('requiredArray', {
    validate (value) {
        return !_.isEmpty(value)
    },
    message: 'This field is required',
    computesRequired: true
});
// Register it globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
// End of Vee Validate Stuff

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
Vue.config.devtools = true;
Vue.component('video-upload-test-component', VideoUploadTestComponent);
Vue.component('nav-arrow', FlashAlert);
Vue.component('pagination', LaravelVuePagination);
Vue.component('flash-alert', FlashAlert);
Vue.component('error-message', ErrorMessage);
Vue.component('order-vp-form', OrderVpForm);
Vue.component('order-emmy-network-form', OrderEmmyNetworkForm);
Vue.component('ordernetwork-form', OrderVpForm);
Vue.component('order-delete', OrderDelete);
Vue.component('signature-pad', SignaturePad);
Vue.component('fyc-cropper', FycCropper);
Vue.component('instructions', Instructions);
Vue.component('create-order', CreateOrder);
Vue.component('checkout-table', CheckoutTable);
Vue.component('invoice-payment', InvoicePayment);
Vue.component('verify-microdeposits', VerifyMicrodeposits);
Vue.component('admin-order-status-update', AdminOrderStatusUpdate);
Vue.component('financials-table', FinancialsTable);
Vue.component('invoice-form', InvoiceForm);
Vue.component('orders-table', OrdersTable);
Vue.component('admin-search', AdminSearch);
Vue.component('admin-events-search', AdminEventsSearch);
Vue.component('team-assignment-update', TeamAssignmentUpdate);
Vue.component('question-popup', QuestionPopup);
Vue.component('admin-change-user', AdminChangeUser);
Vue.component('admin-internal-notes', AdminInternalNotes);
Vue.component('video-component-upload', VideoUploadComponent);
Vue.component('tabular-report', TabularReport);
Vue.component('file-upload', FileUpload);
Vue.component('file-upload-in-templates', FileUploadInTemplates);
Vue.component('wysiwyg-input', WysiwygInput);
Vue.component('users-table', UsersTable);
Vue.component('phone-number-input', PhoneNumberInput);
Vue.component('password-input', PasswordInput);

Vue.component('create-event', CreateEvent);
Vue.component('event-order-form', EventOrderForm);
Vue.component('events-table', EventsTable);
Vue.component('events-calendar', EventsCalendar);
Vue.component('admin-statuses', AdminStatuses);
Vue.component('webpage-desktop-preview', WebpageDesktopPreview);
Vue.component('month-accordion', MonthAccordion);
Vue.component('wysiwyg-readonly-toggle', WysiwygReadonlyToggle);
Vue.component('events-list-view', EventsListView);

// Sentry.init({
//     Vue,
//     dsn: "https://d2b12316586447d0a39d5dc1ca2f5bd6@o47613.ingest.sentry.io/1828252",
//     integrations: [new Integrations.BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.1,
// });

Vue.mixin({
    methods: {
        formatMoney(amount) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 2,
            }).format(amount);
        },
    },
})

const app = new Vue({
    el: '#app',
    data() {
        return {
            showLogout: false,
            showEvents: false,
            showScreenings: false,
            showMessage: true,
            date_config: {dateFormat: 'm-d-Y'},
            alertShow: false
        }
    },
    mounted() {
        var left      = document.getElementById("scrollTop"),
            stop      = (left && left.offsetTop) ? left.offsetTop - 120 : 0,
            docBody   = document.documentElement || document.body.parentNode || document.body,
            hasOffset = window.pageYOffset !== undefined,
            scrollTop;

        window.onscroll = function (e) {
            scrollTop = hasOffset ? window.pageYOffset : docBody.scrollTop;
            if (scrollTop >= stop && left) {
                if(scrollTop >= 121) {
                    left.style.top = '5px';
                } else {
                    left.style.top = 120 - scrollTop + 'px';
                }
            } else if(left) {
                left.style.top =  '121px';
            }
        }
    },
    methods: {
        showFlashAlert: function() {
            this.alertShow = true;
        },
    },
});

window.countWords = function(s) {
    s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
    s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
    return s.split(' ').length;
}
