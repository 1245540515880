<template>

    <div class="col-12">
        <div v-if="errors">
            {{ errors }}
        </div>
        <form>

            <div class="row">
                <div class="form-search-wrap col-12">
                    <div class="choices-filter-group double position-relative">
                        <input class="padding-right-big" type="text" name="keyword" id="keyword" placeholder="Enter a keyword, order number, program name, or network name" v-model="query_keyword" >
                        <label class="search-icon-placement no-border search-button" for="keyword" @click="keyword_search()">
                            <button class="btn-bigger btn-wide">Search</button>
                        </label>
                    </div>
                </div>

                <div class="col-3 pt-2">
                    <div class="form-floating mb-3">
                        <v-select multiple id="query_order_status" :placeholder="`Order Status`" v-model="query_order_status" label="text" :reduce="text => text.id" :options="order_status_options" ></v-select>
                    </div>
                </div>
                <div class="col-3 pt-2 single">
                    <v-select multiple id="query_asset_status" :placeholder="`Asset Status`" v-model="query_asset_status" label="text" :reduce="text => text.id" :options="asset_status_options" ></v-select>
                </div>
                <div class="col-3 pt-2">
                    <v-select multiple id="query_event_type" :placeholder="`Event Type`" v-model="query_event_type" :options="event_type_options" ></v-select>
                </div>
                <div class="col-3 pt-2">
                    <v-select multiple id="query_file_status" :placeholder="`Video File Status`" v-model="query_file_status"  :options="file_status_options" ></v-select>
                </div>
                <div class="col-5">
                    <label class="d-inline-flex align-items-center border input-wrapper input-wrapper-date mr-3">
                        <calendar-icon></calendar-icon>
                            <flat-pickr value="" :config="{
                            enableTime: false,
                            dateFormat: 'm-d-Y',
                            altInput: true,
                            altFormat: 'F j, Y',
                            }" placeholder="Event Date - Start" v-model="query_event_date_start" id="query_event_date_start"></flat-pickr>
                    </label>
                    <label class="d-inline-flex align-items-center border input-wrapper input-wrapper-date">
                        <calendar-icon></calendar-icon>
                        <flat-pickr value="" :config="{
                            enableTime: false,
                            dateFormat: 'm-d-Y',
                            altInput: true,
                            altFormat: 'F j, Y',
                            }" placeholder="Event Date - End" v-model="query_event_date_end" id="query_event_date_start"></flat-pickr>
                    </label>
                </div>
                <div class="col-5">
                    <label class="d-inline-flex align-items-center border input-wrapper input-wrapper-date mr-3">
                        <calendar-icon></calendar-icon>
                        <flat-pickr value="" :config="{
                        enableTime: false,
                        dateFormat: 'm-d-Y',
                        altInput: true,
                        altFormat: 'F j, Y',
                        }" placeholder="Email Send Date - Start" v-model="query_send_date_start" id="query_send_date_start"></flat-pickr>
                    </label>
                    <label class="d-inline-flex align-items-center border input-wrapper input-wrapper-date">
                        <calendar-icon></calendar-icon>
                        <flat-pickr value="" :config="{
                        enableTime: false,
                        dateFormat: 'm-d-Y',
                        altInput: true,
                        altFormat: 'F j, Y',
                        }" placeholder="Email Send Date - End" v-model="query_send_date_end" id="query_send_date_end"></flat-pickr>
                    </label>
                </div>
                <div class="col-2 pt-2 text-right">
                    <a class="clear-button no-border cursor-pointer" v-on:click.stop="clearSearch()"><close-icon class="svg-16 svg-accent"></close-icon> Clear Search</a>
                    <a :href="csvUrl()" class="btn btn-primary ml-2"><download class="svg-black"></download> CSV Export</a>
                </div>
            </div>

        </form>
        <br/>

        <div class="entries-table-wrap">
            <table id="sortable-table nice-table">
                <thead>

                <tr class="tableHeaderRow">
                    <th v-if="(view_mode=='team-assignment')" style="width: 160px;" class="th-entry pl-2" v-on:click="setSort('team_assigned')">Team Assigned <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'team_assigned'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 130px;" class="th-entry pl-2" v-on:click="setSort('order_id')">Order # <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_id'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 160px;" class="th-your-name" v-on:click="setSort('your_name')">Submitter Name <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'your_name'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 200px;" class="th-event-title" v-on:click="setSort('event_title')">Event Title <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'event_title'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 105px;" class="th-event_date" v-on:click="setSort('event_date')">Event Date <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'event_date'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 100px;" class="th-send_at" v-on:click="setSort('send_at')">Email Date <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'send_at'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th class="th-type" style="width: 85px;" v-on:click="setSort('type')">Type <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'type'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th class="th-network" style="width: 100px;" v-on:click="setSort('network_platform')">Network <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'network_platform'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th class="th-program" style="width: 100px;" v-on:click="setSort('program_title')">Program <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'program_title'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 130px;" class="th-status" v-on:click="setSort('order_status')">
                        Order Status <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'order_status'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                    <th style="width: 130px;" class="th-status" v-on:click="setSort('asset_status')">
                        Asset Status <up-chevron-icon v-if="((sort_order != 'desc') && (sort == 'asset_status'))"></up-chevron-icon> <down-chevron-icon v-else></down-chevron-icon></th>
                </tr>
                </thead>
                <tbody>
                <tr @click="loadOrder(order.order_id)" :id="'order-'+order.order_id" style="cursor: pointer"  v-for="(order,i) in orders.data">
                    <td v-if="(view_mode=='team-assignment')" ><div>
                        <team-assignment-update
                            :team_assigned_user="order.team_assigned_user"
                            :team_assigned_members="team_assigned_members"
                            reload_after_update=false
                            :actionurl_team_assigned="'event/'+order.order_id+'/team_assigned/update'"
                        ></team-assignment-update>
                    </div></td>
                    <td  class="pl-2"><div>{{ order.order_id }}</div></td>
                    <td><div>{{ order.your_name }}</div></td>
                    <td><div>{{ order.event_title }}</div></td>
                    <td><div>{{ order.event_date }}<br>{{ order.time_slot == 'Open' ? order.time_slot_other : order.time_slot }}</div></td>
                    <td><div v-if="order.send_at">{{ order.send_at }}</div></td>
                    <td><div>{{ order.type }}</div></td>
                    <td><div>{{ order.network_platform == 'Other' ? order.network_platform_other : order.network_platform }}</div></td>
                    <td><div>{{ order.program_title }}</div></td>
                    <td class="status">
                        <div class="">
                            {{ order.order_status }}
                        </div>
                    </td>
                    <td class="status">
                        <div class="">
                            {{ order.asset_status }}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style="float:right;">
            <pagination :data="orders" @pagination-change-page="paginationCalculator" :limit="3">
                <span slot="prev-nav">&lt; Previous</span>
                <span slot="next-nav">Next &gt;</span>
            </pagination>
        </div>
        <div class="dataTables_info" v-if="to">Showing 1-{{to}} of {{ total }} events</div>
        <div class="dataTables_info" v-else>Showing 0 events</div>
        <div class="dataTables_length admin-entry-table">
            <label>
                <span class="pr-2">Show</span>
                <select class="plain-select num-entries-select" v-model="num">
                    <option value="10">10 Events</option>
                    <option value="20">20 Events</option>
                    <option value="50">50 Events</option>
                    <option value="100">100 Events</option>
                </select>
                <span>per page</span>
            </label>
        </div>

    </div>
</template>

<script>
    import CloseIcon from "@/Icons/close.vue";
    import DownChevronIcon from "@/Icons/down_chevron.vue";
    import UpChevronIcon from "@/Icons/up-chevron.vue";
    import SearchIcon from "@/Icons/search.vue";
    import CalendarIcon from "@/Icons/calendar.vue";
    import Download from "@/Icons/download.vue";
    export default {
        name: "AdminEventsSearch",
        components: {Download, CalendarIcon, SearchIcon, UpChevronIcon, DownChevronIcon, CloseIcon},
        props: {
            team_assigned_user: String,
            team_assigned_members: Array,
            view_mode: String
        },
        data() {
            const storedValues = {
                search: localStorage.getItem('fyc-admin-event-history-search'),
                searchStatus: localStorage.getItem('fyc-admin-event-history-search-status'),
                assetStatus: localStorage.getItem('fyc-admin-event-history-search-asset-status'),
                searchType: localStorage.getItem('fyc-admin-event-history-search-event-type'),
                searchFileStatus: localStorage.getItem('fyc-admin-event-history-search-filestatus'),
            };

            return {
                errors: false,
                num:20,
                page:1,
                total: 0,
                to: 0,
                sort:'',
                sort_order: 'desc',
                orders: {},
                query_keyword: (storedValues.search && storedValues.search !== 'null') ? storedValues.search : '',
                query_order_status: (storedValues.searchStatus && storedValues.searchStatus !== 'null') ? storedValues.searchStatus : '',
                query_asset_status: (storedValues.assetStatus && storedValues.assetStatus !== 'null') ? storedValues.assetStatus : '',
                query_event_type: (storedValues.searchType && storedValues.searchType !== 'null') ? storedValues.searchType : '',
                query_event_date_start: (storedValues.searchDateStart && storedValues.searchDateStart !== 'null') ? storedValues.searchDateStart : '',
                query_event_date_end: (storedValues.searchDateEnd && storedValues.searchDateEnd !== 'null') ? storedValues.searchDateEnd : '',
                query_send_date_start: (storedValues.searchDateSendStart && storedValues.searchDateSendStart !== 'null') ? storedValues.searchDateSendStart : '',
                query_send_date_end: (storedValues.searchDateSendEnd && storedValues.searchDateSendEnd !== 'null') ? storedValues.searchDateSendEnd : '',
                query_file_status: (storedValues.searchFileStatus && storedValues.searchFileStatus !== 'null') ? storedValues.searchFileStatus : '',
                order_status_options: [
                    { text: "In Progress", id: "in_progress" },
                    { text: "Order Submitted", id: "submitted" },
                    { text: "Need More Information", id: "need_more_info" },
                    { text: "Order Approved", id: "approved" },
                    { text: "Order Completed", id: "order_completed" },
                    { text: "Order Canceled", id: "canceled" },
                ],
                asset_status_options: [
                    { text: "In Progress", id: "in_progress" },
                    { text: "Assets Submitted", id: "submitted" },
                    { text: "Need More Information", id: "need_more_info" },
                    { text: "Internal Review", id: "internal_review" },
                    { text: "Approved For Go Live", id: "approved" },
                    { text: "Build Underway", id: "building" },
                    { text: "Build Complete", id: "build_complete" },
                    { text: "Published & Sent", id: "published" },
                    { text: "Canceled", id: "canceled" },
                ],
                event_type_options: [
                    'Virtual',
                    'In-Person',
                    'Hybrid',
                ],
                file_status_options: [
                    'Uploading',
                    'Upload Failed',
                    'Upload Successful',
                    'Closed Caption File Submitted',
                    'Processing',
                    'Processing Failed',
                    'Under Review',
                    'Need More Info',
                    'Video Approved',
                    'Canceled',
                    'Deleted',
                ],
            }
        },
        mounted() {
            const storedValues = {
                search: localStorage.getItem('fyc-admin-event-history-search'),
                searchStatus: localStorage.getItem('fyc-admin-event-history-search-status'),
                assetStatus: localStorage.getItem('fyc-admin-event-history-search-asset-status'),
                searchType: localStorage.getItem('fyc-admin-event-history-search-event-type'),
                searchDateStart: localStorage.getItem('fyc-admin-event-history-search-event-date-start'),
                searchDateEnd: localStorage.getItem('fyc-admin-event-history-search-event-date-end'),
                searchDateSendStart: localStorage.getItem('fyc-admin-event-history-search-event-send-start'),
                searchDateSendEnd: localStorage.getItem('fyc-admin-event-history-search-event-send-end'),
                searchFileStatus: localStorage.getItem('fyc-admin-event-history-search-filestatus'),
                searchSort: localStorage.getItem('fyc-admin-event-history-search-sort'),
                searchSortOrder: localStorage.getItem('fyc-admin-event-history-search-sort-order'),
                searchNum: localStorage.getItem('fyc-admin-event-history-search-num'),
                searchPage: localStorage.getItem('fyc-admin-event-history-search-page'),
            };

            if (storedValues.searchSort && storedValues.searchSort !== 'null') {
                this.sort = storedValues.searchSort;
            }
            if (storedValues.searchSortOrder && storedValues.searchSortOrder !== 'null') {
                this.sort_order = storedValues.searchSortOrder;
            }
            if (storedValues.searchNum && storedValues.searchNum !== 'null') {
                this.num = storedValues.searchNum;
            }
            if (storedValues.searchPage && storedValues.searchPage !== 'null') {
                this.page = storedValues.searchPage;
            }
            if (storedValues.searchStatus && storedValues.search !== 'null') {
                this.query_order_status = storedValues.searchStatus.split(',');
            }
            if (storedValues.assetStatus && storedValues.search !== 'null') {
                this.query_asset_status = storedValues.assetStatus.split(',');
            }
            if (storedValues.searchFileStatus && storedValues.searchFileStatus !== 'null') {
                this.query_file_status = storedValues.searchFileStatus.split(',');
            }
            if (storedValues.searchType && storedValues.searchType !== 'null') {
                this.query_event_type = storedValues.searchType.split(',');
            }
            if (storedValues.searchDateStart && storedValues.searchDateStart !== 'null') {
                this.query_event_date_start = storedValues.searchDateStart;//.split(',');
            }
            if (storedValues.searchDateEnd && storedValues.searchDateEnd !== 'null') {
                this.query_event_date_end = storedValues.searchDateEnd;//.split(',');
            }
            if (storedValues.searchDateSendStart && storedValues.searchDateSendStart !== 'null') {
                this.query_send_date_start = storedValues.searchDateSendStart;//.split(',');
            }
            if (storedValues.searchDateSendEnd && storedValues.searchDateSendEnd !== 'null') {
                this.query_send_date_end = storedValues.searchDateSendEnd;//.split(',');
            }
            this.fetchOrders(this.page);
        },
        methods:{
            keyword_search() {
                localStorage.removeItem('fyc-admin-event-history-search-page');
                localStorage.setItem('fyc-admin-event-history-search', this.query_keyword);
                this.fetchOrders(this.page);
            },
            loadOrder(order_id){
                if (this.view_mode != 'team-assignment') {
                    window.location = '/event/'+order_id;
                }

            },
            fetchOrders(page = 1){
                let app = this;
                axios.get('/api/events', {
                    params: {
                        num: app.num,
                        page: page,
                        sort: app.sort,
                        sort_order: app.sort_order,
                        keyword: app.query_keyword,
                        order_status: app.query_order_status,
                        asset_status: app.query_asset_status,
                        file_status: app.query_file_status,
                        type: app.query_event_type,
                        event_date_start: app.query_event_date_start,
                        event_date_end: app.query_event_date_end,
                        send_date_start: app.query_send_date_start,
                        send_date_end: app.query_send_date_end,
                        }
                    })
                    .then(res => {
                        app.orders = res.data;
                        app.total = res.data.total;
                        app.to = res.data.to;
                        localStorage.setItem('fyc-admin-event-history-search-to', app.to);
                        localStorage.setItem('fyc-admin-event-history-search', app.query_keyword);
                        localStorage.setItem('fyc-admin-event-history-search-status', app.query_order_status);
                        localStorage.setItem('fyc-admin-event-history-search-asset-status', app.query_asset_status);
                        localStorage.setItem('fyc-admin-event-history-search-filestatus', app.query_file_status);
                        localStorage.setItem('fyc-admin-event-history-search-event-type', app.query_event_type);
                        localStorage.setItem('fyc-admin-event-history-search-event-date-start', app.query_event_date_start);
                        localStorage.setItem('fyc-admin-event-history-search-event-date-end', app.query_event_date_end);
                        localStorage.setItem('fyc-admin-event-history-search-event-send-start', app.query_send_date_start);
                        localStorage.setItem('fyc-admin-event-history-search-event-send-end', app.query_send_date_end);
                        localStorage.setItem('fyc-admin-event-history-search-num', app.num);
                        let hashVal = window.location.hash;
                        if(hashVal && (hashVal.length)) {
                            setTimeout(function () {
                                app.$scrollTo(hashVal, 500,{
                                    offset: -61});
                            }, 300)
                        }
                    }).catch(err => {
                    this.errors = 'Error contacting the server : ';
                    this.errors += err;
                    console.log(err)
                });
            },
            csvUrl() {
                let app = this;
                let data = {
                    csv: true,
                    num: app.num,
                    sort: app.sort,
                    sort_order: app.sort_order,
                    keyword: app.query_keyword,
                    event_date_start: app.query_event_date_start,
                    event_date_end: app.query_event_date_end,
                    send_date_start: app.query_send_date_start,
                    send_date_end: app.query_send_date_end,
                }
                let searchParams = new URLSearchParams(data);

                if(app.query_order_status && Array.isArray(app.query_order_status)) {
                    app.query_order_status.forEach(function(item, index) {
                        searchParams.append('order_status[]', item);
                    });
                }
                if(app.query_asset_status && Array.isArray(app.query_asset_status)) {
                    app.query_asset_status.forEach(function(item, index) {
                        searchParams.append('asset_status[]', item);
                    });
                }
                if(app.query_file_status && Array.isArray(app.query_file_status)) {
                    app.query_file_status.forEach(function(item, index) {
                        searchParams.append('file_status[]', item);
                    });
                }
                if(app.query_event_type && Array.isArray(app.query_event_type)) {
                    app.query_event_type.forEach(function(item, index) {
                        searchParams.append('type[]', item);
                    });
                }

                return '/event-search-export?' + searchParams.toString();
            },
            setSort(sort) {
                if (this.sort == sort) {
                    this.sort_order = (this.sort_order == 'desc')?'asc':'desc';
                } else {
                    this.sort = sort;
                    this.sort_order = 'desc';
                }
                localStorage.setItem('fyc-admin-event-history-search-sort', this.sort);
                localStorage.setItem('fyc-admin-event-history-search-sort-order', this.sort_order);
                this.fetchOrders();
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace(',', '.')
                return '$'+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            openAssignModal(i){
                this.assigned_invoice = this.invoices[i];
                this.check_assign_amount = this.assigned_invoice.amount_due.toFixed(2);
                this.assignCheckModal = true;
            },
            assignCheck() {
                let app = this;
                if (!(app.check_assign_amount > 0)) {
                    alert("Check amount needs to be a value");
                    return;
                }
                app.assignCheckModal = false;
                axios.post('/api/finance/apply_check', {
                    check_assign_amount: app.check_assign_amount,
                    check_assign_number: app.check_assign_number,
                    check_assign_notes: app.check_assign_notes,
                    invoice_id: app.assigned_invoice.id,
                })
                    .then(res => {
                        this.errors = res.data;
                        window.location.reload();
                    }).catch(err => {
                    this.errors = 'Error contacting the server : ';
                    this.errors += err;
                    console.log(err);
                    window.location.reload();
                });
            },
            clearSearch: function() {
                this.query_keyword = '';
                this.sort = '';
                this.sort_order = 'desc';
                this.num = 20;
                this.query_order_status = '';
                this.query_asset_status = '';
                this.query_file_status = '';
                this.query_event_type = '';
                this.query_event_date_start = '';
                this.query_event_date_end = '';
                this.query_send_date_start = '';
                this.query_send_date_end = '';
                localStorage.removeItem('fyc-admin-event-history-search');
                localStorage.removeItem('fyc-admin-event-history-search-status');
                localStorage.removeItem('fyc-admin-event-history-search-asset-status');
                localStorage.removeItem('fyc-admin-event-history-search-filestatus');
                localStorage.removeItem('fyc-admin-event-history-search-event-type');
                localStorage.removeItem('fyc-admin-event-history-search-sort');
                localStorage.removeItem('fyc-admin-event-history-search-sort-order');
                localStorage.removeItem('fyc-admin-event-history-search-num');
                localStorage.removeItem('fyc-admin-event-history-search-page');
            },
            paginationCalculator: function(pageIndex) {
                localStorage.setItem('fyc-admin-event-history-search-page', pageIndex);
                this.fetchOrders(pageIndex);
            },
        },
        watch: {
            query_order_status() {
                localStorage.removeItem('fyc-admin-event-history-search-page');
                this.fetchOrders(this.page);
                },
            query_asset_status() {
                localStorage.removeItem('fyc-admin-event-history-search-page');
                this.fetchOrders(this.page);
            },
            query_file_status() {
                localStorage.removeItem('fyc-admin-event-history-search-page');
                this.fetchOrders(this.page);
            },
            query_event_type() {
                localStorage.removeItem('fyc-admin-event-history-search-page');
                this.fetchOrders(this.page);
            },
            query_event_date_start() {
                localStorage.removeItem('fyc-admin-event-history-search-page');
                this.fetchOrders(this.page);
            },
            query_event_date_end() {
                localStorage.removeItem('fyc-admin-event-history-search-page');
                this.fetchOrders(this.page);
            },
            query_send_date_start() {
                localStorage.removeItem('fyc-admin-event-history-search-page');
                this.fetchOrders(this.page);
            },
            query_send_date_end() {
                localStorage.removeItem('fyc-admin-event-history-search-page');
                this.fetchOrders(this.page);
            },
            num() {
                this.page = 1;  // Reset to first page when number of items changed
                this.fetchOrders(this.page);
                },
        }
    }
</script>
